import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
const columns = [


    { field: "count", name: "العدد ", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المنتج", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: false,
    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            file: '',
            spending: 0,
            saffe: 0,


        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    Orders() {
        var bodyFormData = new FormData();
        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        bodyFormData.append('file', this.state.file);
        axios({
            method: "post",
            url: host + `users/orders/done/`,
            data: bodyFormData,
            headers: header,
        })
            .then(response => {
                console.log(response.data.data.data);
                this.setState(({
                    orders: response.data.data.data
                }))
            })
            .catch(response => {
                console.log(response);

            })
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>

                                </div>

                                <div id='ContinerReportDate'>
                                    <Row id='RowReportDate'>
                                        <Col id='ColReportDate' >
                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>اختار ملف</Form.Label>
                                                <Form.Control type="file" onChange={(e) => {

                                                    this.setState({ file: e.target.files[0] })
                                                }} />

                                            </Form.Group>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>تحميل</div>
                                        </Col>

                                    </Row>
                                </div>
                                {!this.state.serch ? null : (
                                    <div><Table striped bordered hover size="md">
                                        <thead style={{
                                            textAlign: 'center',
                                            fontSize: '24px',
                                            fontWeight: 'bold'
                                        }}>
                                            <tr>
                                                <th>المحافظة</th>
                                                <th>سعر في الكشف</th>
                                                <th>سعر في السستم</th>
                                                <th>رقم الوصل</th>
                                                <th>#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.orders.map((item, i) =>
                                                <tr key={i} style={item.check === 1 ? {
                                                    textAlign: 'center',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                    background: "aquamarine"
                                                } : {
                                                    textAlign: 'center',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                    background: "lightpink"
                                                }}>
                                                    <td>{item.city}</td>
                                                    <td>{item.k_price}</td>
                                                    <td>{item.order_price}</td>
                                                    <td>{item.id}</td>
                                                    <td>{i + 1}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                        <hr></hr>
                                        <div style={{ display: "flex", justifyContent: "center", margin: "20px" }} onClick={() => {
                                            let array = []
                                            for (let index = 0; index < this.state.orders.length; index++) {
                                                if (this.state.orders[index].check === 1) {
                                                    array.push(this.state.orders[index].id)
                                                }
                                            }
                                        }}>
                                            <Button>انجاح</Button>
                                        </div>
                                    </div>

                                )}

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;