import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Card, Form, Image, Table, } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Button, Select } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import DeleteForeverIcon from '@material-ui/icons/Cancel';
import Clear from '@material-ui/icons/Clear';

import Badge from 'react-bootstrap/Badge';


// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'





// import { JsonToExcel } from "react-json-to-excel";


const columns = [


    { field: "notes", name: " ملاحظات", options: { filter: true, sort: true, } },



    { field: "notes2", name: "المنتج", options: { filter: true, sort: true, } },



    //  { field: "items_count", name: "عدد القطع", options: { width: 20, filter: true, sort: true, } },
    //   { field: "safee", name: "الصافي", options: { width: 15, filter: true, sort: true, } },

    { field: "price", name: "السعر", options: { width: 5, filter: true, sort: true, } },
    { field: "government", name: "العنوان", options: { width: 15, filter: true, sort: true, } },
    { field: "phone", name: "رقم الهاتف", options: { width: 100, filter: true, sort: true, } },

    { field: "company", name: "شركة التوصيل", options: { width: 100, filter: true, sort: true, } },

    { field: "date", name: "تاريخ التسليم", options: { width: 15, filter: true, sort: true, } },
    { field: "user", name: "مثبت الطلب", options: { width: 30, filter: true, sort: false, } },
    { field: "id", name: "رقم الوصل", options: { width: 15, filter: true, sort: false, } },
    //   { field: "i", name: "#", options: { filter: true, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 30000,
    search: false,
    filter: false,
    viewColumns: true,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            id: '',
            cash: '',
            cash_orders: '',
            total_price: "",
            t_safee: 0,
            createdAt: ''
        }

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })

    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }
    componentDidMount() {
        let header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `dashbord/shiper/all/`, { headers: header })
            .then(res => {

                this.setState({ shiper: res.data.shiper })

            })
            .catch(error => {
                console.log(error.response)
            });

        this.Orders()
    }

    recjeted(item, id) {

        let data = []
        // const sum = item.reduce((a, {watt}) => a + watt, 0);

        for (let index = 0; index < item.length; index++) {

            let count = document.getElementById(`item${item[index].id}`).value
            let obj = {
                id: item[index].id,
                count: Number(count)
            }
            data.push(obj)
        }
        console.log(data)
        const Oldsum = item.reduce((a, { count }) => a + count, 0);
        const newsum = data.reduce((a, { count }) => a + count, 0);
        let check = 0;

        if (Oldsum > newsum) {
            check = 1;
        }

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();

        if (check === 1) {
            formData.append("check", 1);
        } else {
            formData.append("check", 0);
        }
        formData.append("data", JSON.stringify(data));
        axios({ url: host + `dashbord/form/orders/rejected/${id}`, method: "post", data: formData, headers: header })
            .then(response => {
                this.componentDidMount();
                toast('تم ارجاع الطلب', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                return true;
            })
            .catch(error => { console.log(error) })

    }
    Orders() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };


        axios.get(host + `users/replacement/all`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.orders
                let ordes2 = []
                for (let i = 0; i < items.length; i++) {

                    let obj = {
                        i: i + 1,
                        date: moment(items[i].printerOrders[0].prints.createdAt).format('DD/MM/YYYY'),
                        company: items[i].printerOrders[0].prints.name,
                        id: items[i].id,
                        phone: items[i].phone,
                        name: items[i].name,
                        replacement: "",
                        calls_d: '',
                        user: items[i].user.name,
                        government: items[i].government,
                        city: items[i].city,
                        price: items[i].price,
                        safee: items[i].price - items[i].shping_price,
                        notes2: items[i].notes2,
                        calls: <p style={{ color: "#dc0505" }}>{items[i].calls}</p>,
                        items_count: 1,
                        notes: items[i].notes,
                        createdAt: items[i].createdAt,


                    };

                    var a = moment(moment(items[i].printerOrders[0].prints.createdAt));
                    var b = moment(new Date());
                    // 1pi
                  
                    if (b.diff(a, 'days') >= 14) {
                   
                        obj.date = <p style={{ color: 'red' }}>{moment(items[i].printerOrders[0].prints.createdAt).format('DD/MM/YYYY')}</p>
                    } else {
                        obj.date = <p>{moment(items[i].printerOrders[0].prints.createdAt).format('DD/MM/YYYY')}</p>

                    }

                    arr.push(obj);

                }


                this.setState({
                    orders_count: items.length,
                    orders: arr,
                    spin: false,

                    // total_orders: res.data.data.order_count,
                    // items_cost: res.data.data.items_cost,
                    // orders_cost: res.data.data.sells,
                    ordes2,

                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    add(value) {

        axios.post(host + `users/list/add`,
            qs.stringify({
                list_id: id,
                order_id: value,

            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)
                document.getElementById('orderFiled').value = ''
                toast('تمت الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {
                console.log(error.message);
                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)
                this.setState({ spinerBtn: false })
            })
    }

    delete(value) {

        axios.delete(host + `users/list/${value}`, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.Orders(id)

                toast('ازلة الطلب من القائمة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });

            })
            .catch(error => {

                toast.error("حصل خطأ تأكد من المعلومات", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error)

            })

    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                        
                                
                                <br />
                      

                                <div>
                            


                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                        
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable title='الاستبدالات' data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;