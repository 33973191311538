import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon, Button } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Form, Image, Table, Container } from 'react-bootstrap';

import Backspace from '@material-ui/icons/Backspace';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import DoneIcon from '@material-ui/icons/DoneAll';
import AccountCircle from '@material-ui/icons/Instagram';
import PhoneCallback from '@material-ui/icons/PhoneCallback';
import Clear from '@material-ui/icons/Clear';
import CategoryIcon from '@material-ui/icons/Category';
import Autorenew from '@material-ui/icons/Autorenew';
import HistoryToggleOff from '@material-ui/icons/History';


import SpeakerNotes from '@material-ui/icons/SpeakerNotes';
import DataTable from 'react-data-table-component';
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MoneyOff from '@material-ui/icons/MoneyOff';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
import * as moment from "moment-timezone";
// const columns = [


//     { field: "text", name: "الغاء", options: { filter: true, sort: false, } },
//     { field: "notes", name: " ملاحظات", options: { filter: true, sort: true, } },
//     { field: "notes2", name: "المنتج", options: { filter: true, sort: true, } },
//     { field: "price", name: "السعر", options: { width: 5, filter: true, sort: true, } },
//     { field: "government", name: "العنوان", options: { width: 15, filter: true, sort: true, } },
//     { field: "count", name: "عدد القطع", options: { width: 15, filter: true, sort: true, } },
//     { field: "id", name: "رقم الوصل", options: { width: 15, filter: true, sort: false, } },
//     //   { field: "i", name: "#", options: { filter: true, sort: false, } },

// ];
const columns = [


    {
        name: 'الغاء',
        selector: row => row.delete,
        sortable: false,
        cell: row => <div style={{ textAlign: "center", fontSize: 30 }}>{row.delete}</div>



    },
    {
        name: 'المنتج',
        selector: row => row.notes2,
        sortable: false,



    },
    {
        name: 'القطع',
        selector: row => row.count,
        width: "100px",
        sortable: false,
    },
    {
        name: 'العنوان',
        selector: row => row.government + " / " + row.city,
        sortable: false,

        textAlign: "center",
        cell: row => <div style={{ textAlign: "center" }}>{row.government}</div>

    },
    {
        name: 'السعر',
        selector: row => row.price,
        width: "150px",
        sortable: false,
    },

    {
        name: 'رقم الوصل',
        selector: row => row.id,
        sortable: false,
        width: "180px",
    },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 30000,
    search: false,
    filter: false,
    viewColumns: true,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const customStyles = {

    rows: {
        style: {
            minHeight: '72px', // override the row height
            border: 'solid 1px black',
            overflow: "hidden",
            display: "-webkit-box",

        },
    },

    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            justifyContent: 'center',
            ' font-size': '25px',
            'font-weight': 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            border: 'solid 1px black',
            ' font-size': '20px',
            'font-weight': 'bold',
            justifyContent: 'center',

        },
    },
};
const conditionalRowStyles = [
    {
        when: row => row.check,
        style: {
            backgroundColor: "#d50014",
            userSelect: "none",
            color: 'white'
        }
    },
];
const cookies = new Cookies();

class fromOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            orders: [],
            spin: false,
            totalCount: '',
            nameItem: '',
            priceItem: '',
            msg: '',
            name: "",

            image: [],
        }
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })
    onChangeD(value) {
        let count = document.getElementById(value).value;
        if (count > 0) {
            let newValue = Number(document.getElementById(value).value) - 1;
            document.getElementById(value).value = newValue
            // let re = ((item.count - count + 1) + 1)
            // console.log(re);
            // document.getElementById(text).textContent = `الكمية المتبقية ${re}`

        }

    }

    componentDidMount() {


        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };

        axios.get(host + `dashbord/profile`, { headers: header })
            .then(res => {

                this.setState({ name: res.data.data.profile.name })
            })
            .catch(error => { window.location.href = '/' })


        axios.get(host + `dashbord/Packaging/orders/my`, { headers: header })
            .then(res => {

                let orders = res.data.order;

                let arry = []
                for (let index = 0; index < orders.length; index++) {
                    let check = false
                    if (orders[index].items_count > 1) {
                        check = true

                    }
                    let obj = {
                        id: orders[index].id,
                        price: orders[index].price,
                        check,
                        notes: orders[index].notes,
                        notes2: orders[index].notes2,
                        government: orders[index].government + " / " + orders[index].city,
                        notes2: orders[index].notes2,
                        count: orders[index].items_count,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title={`حذف الطلب رقم ${orders[index].id}`}
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.Delete(orders[index].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل متأكد ؟ حذف الطلب رقم {orders[index].id}</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373', fontSize: 30 }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    }
                    arry.push(obj)
                }
                this.setState({
                    orders: arry, spin: false
                });
            })
            .catch(error => { console.log(error.response) })

    }

    Delete(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };

        axios({ url: host + `dashbord/Packaging/delete/${id}`, method: "POST", headers: header })

            .then(response => {


                toast('تم الحذف  بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
    }


    add(id) {

        if (id !== "ss") {
            document.getElementById('searchINN').value = ""
            var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };

            document.getElementById('sBtn').style.display = "none"
            document.getElementById('loadingBtn').style.display = "flex"
            axios({ url: host + `dashbord/Packaging/order/${id}`, method: "POST", headers: header })

                .then(response => {
                    document.getElementById('sBtn').style.display = "flex"
                    document.getElementById('loadingBtn').style.display = "none"

                    if (response.data.check == 1) {
                        document.getElementById('root').style.background = "brown"
                        window.alert('هذا الطلب يحتوى على اكثر من قطعة هل تأكدت من ذلك.؟')
                    } else {
                        document.getElementById('root').style.background = "#fff"
                    }
                    toast('تم الاضافة بنجاح', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    this.componentDidMount();
                })
                .catch(error => {
                    document.getElementById('sBtn').style.display = "flex"
                    document.getElementById('loadingBtn').style.display = "none"
                    if (error.response.data) {
                        toast.error(error.response.data.message, {
                            position: "bottom-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true
                        });
                        window.alert(error.response.data.message)
                    }

                })
        }

    }



    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        return (
                            <div >

                                <div id="searchDiv" style={{ justifyContent: "center" }}>
                                    <Button id="sBtn" appearance="primary" intent="success" size="medium" onClick={(e) => {
                                        let Search = document.getElementById('searchINN').value
                                        if (Search) {
                                            this.add(document.getElementById('searchINN').value)
                                        } else {
                                            this.add('ss')
                                        }

                                    }} >اضافة</Button>



                                    <Button isLoading size="medium" id="loadingBtn" style={{ display: 'none' }}>جاري الاضافة</Button>
                                    <input type="text" placeholder="بحث" id='searchINN' onKeyPress={(e) => {

                                        if (e.key === "Enter") {
                                            if (e.target.value) {
                                                this.add(document.getElementById('searchINN').value)
                                            } else {
                                                this.add('ss')

                                            }
                                        }
                                    }} />
                                    <div>
                                        <Card style={{ width: '10rem' }}>

                                            <Card.Body>
                                                <Card.Title>{this.state.name}</Card.Title>

                                                <Button appearance="primary" intent="danger" onClick={() => {
                                                    cookies.remove("token")
                                                    window.location.href = '/'
                                                }}>تسجيل خروج </Button>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                </div>

                                <div className='DataTableContiner'>
                                    <DataTable highlightOnHover
                                        pointerOnHover direction={'center'}
                                        conditionalRowStyles={conditionalRowStyles}
                                        customStyles={customStyles}
                                        columns={columns}
                                        data={this.state.orders}
                                        selectableRows={false}
                                    // onRowClicked={this.handleRowClicked}

                                    />
                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={true}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default fromOrders;