import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Card, Table, Form, Button, Badge } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment-timezone";
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, TextInput } from 'evergreen-ui';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { ToastContainer, toast } from 'react-toastify';

import Login from '../common/login';
const cookies = new Cookies();

const role = Number(localStorage.getItem('role'))
var colors = ['#2e8b57', '#6748b7', '#581845', '#900C3F', '#2B4C04', '#052E5A'
    , '#23056D', '#6B0759', '#6B0712', '#6B5707', '#0A3BB3'
    , '#076E85', '#7D01A7', '#01A770', '#6C3693', '#210C11'
];
const items = [
    {
        id: 0,
        name: 'Cobol'
    },
    {
        id: 1,
        name: 'JavaScript'
    },
    {
        id: 2,
        name: 'Basic'
    },
    {
        id: 3,
        name: 'PHP'
    },
    {
        id: 4,
        name: 'Java'
    }
]
const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
}

const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
}



const handleOnFocus = () => {
    console.log('Focused')
}

const formatResult = (item) => {
    return (
        <>
            {/* <span style={{ display: 'block', textAlign: 'left' }}>id: {item.id}</span> */}
            <span style={{ display: 'block', textAlign: 'left' }}> {item.name}</span>
        </>
    )
}


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            items_price_sum: '',
            items_dw_price_sum: '',
            items_saffe: '',
            orders_price: '',
            Spending_price: '',
            component_orders_count: '',
            orders_count: '',
            da5al: '',
            shiper: [],
            company: 'All',
            data: [],
            items: [],
            startAt: "",
            baged: "",
            editOff: "",
            item_id: "",
            nunmberOfads: 0,
            allAmount: 0
        }
    }
    componentDidMount() {
        axios.get(host + `users/get/items/all/`)
            .then(res => {
                this.setState({ items: res.data.items })

            })
            .catch(error => {
                console.log(error.response)
            });
    }

    Orders() {
        // var date1 = moment(document.getElementById('date1').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        // var date2 = moment(document.getElementById('date2').value, 'DD/MM/YYYY').format('MM/DD/YYYY');

        var radios = document.getElementsByName('group1');
        let check = ""
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                // do whatever you want with the checked radio

                check = radios[i].value
                // only one radio can be logically checked, don't check the rest
                break;
            }
        }



        var header = { "Content-Type": "application/json", token: cookies.get("token") };
        axios.get(host + `users/campaign/all?active=${check}`, { headers: header })
            .then(res => {
                let a = res.data.data.reports.reduce((n, { baged }) => n + baged, 0)
                this.setState({
                    spin: false,
                    data: res.data.data.reports,
                    nunmberOfads: res.data.data.reports.length,
                    allAmount: a
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    sumbit() {
        const baged = this.state.baged
        const startAt = this.state.startAt
        const item_id = this.state.item_id


        axios.post(host + `dashbord/campaign/new`, {
            baged,
            startAt,
            item_id,
        })
            .then(response => {

                toast('تم الاضافة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                })
            })
            .catch(error => {
                if (error.response) {
                    this.setState({ spinBtn: false })

                }
            });

    }
    handleChangeFrom = date => {

        this.setState({
            from: date
        });
    };
    handleOnSelect = (item) => {
        // the item selected
        this.setState({
            item_id: item.id
        });

    }
    off(id) {
        const off_at = this.state.editOff


        axios.post(host + `dashbord/campaign/edit/${id}`, {
            off_at,

        })
            .then(response => {


            })
            .catch(error => {
                if (error.response) {
                    this.setState({ spinBtn: false })

                }
            });
    }
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='ContinerReportDate'>
                                    <Row id='RowReportDate' style={{ flexDirection: "row-reverse" }}>

                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <Form.Check
                                                inline
                                                label="الجميع"
                                                value={'all'}
                                                name="group1"
                                                type={"radio"}
                                                style={{ margin: 10 }}
                                                id={`inline`}
                                            />
                                            <Form.Check
                                                inline
                                                label="غير فعال"
                                                value={'0'}
                                                name="group1"
                                                style={{ margin: 10 }}
                                                type={"radio"}
                                                id={`inline`}
                                            />
                                            <Form.Check
                                                inline
                                                label="فعال"
                                                value={'1'}
                                                style={{ margin: 10 }}
                                                defaultChecked
                                                name="group1"
                                                type={"radio"}
                                                id={`inline`}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={2}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={2}>
                                            <Badge bg="primary">{this.state.nunmberOfads}</Badge>

                                        </Col>
                                        <Col id='ColReportDate' style={role == -1 ? {} : { display: "none" }} sm={12} md={2}>
                                            <Badge bg="primary">{this.state.allAmount}</Badge>

                                        </Col>

                                        <Col id='ColReportDate' sm={12} md={2}>
                                            <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <React.Fragment>
                                                        <SideSheet
                                                            isShown={state.isShown}
                                                            position={Position.LEFT} ذ
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                        >
                                                            <div margin={40}>
                                                                <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة قسم</div>
                                                                <div id='inputAdd2Continer' >

                                                                    <div id={'ContinerInPut'}>
                                                                        <label>المنتج</label>
                                                                        <div style={{ width: "55%" }}>
                                                                            <ReactSearchAutocomplete
                                                                                items={this.state.items}
                                                                                placeholder="اختر المنتج"
                                                                                onSearch={handleOnSearch}
                                                                                onHover={handleOnHover}
                                                                                onSelect={this.handleOnSelect}
                                                                                onFocus={handleOnFocus}
                                                                                autoFocus
                                                                                styling={{ borderRadius: 0 }}
                                                                                formatResult={formatResult}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div id={'ContinerInPut'}>
                                                                        <label>وقت بدا الحملة</label>
                                                                        <input autoComplete='off' type='date' placeholder='اسم القسم' id='InputTExtDash111'
                                                                            onChange={(e) => this.setState({ startAt: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'}>
                                                                        <label>وقت بدا الحملة</label>
                                                                        <input autoComplete='off' type='number' placeholder='ميزانية ' id='InputTExtDash111'
                                                                            onChange={(e) => this.setState({ baged: e.target.value })} />
                                                                    </div>
                                                                </div>
                                                                <div id='btnAddContiner'>
                                                                    <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                        this.sumbit()
                                                                        setState({ isShown: false })
                                                                    }}>اضافة</div>
                                                                </div>

                                                            </div>
                                                        </SideSheet>
                                                        <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة حملة</div>
                                                    </React.Fragment>
                                                )}
                                            </Component>

                                        </Col>
                                    </Row>
                                </div>

                                {!this.state.serch ? null : (
                                    <div>
                                        <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>
                                            {this.state.data.map((order, index) => (
                                                <Col id='colInfoOffice' >
                                                    <Card style={{ widows: "100%" }}>

                                                        <Card.Body>

                                                            <div>
                                                                <Row>
                                                                    <Col>
                                                                        <center> <h4>{order.id} -{order.name}</h4></center>


                                                                    </Col>
                                                                    <Col><center> <h4>المتبقي في المخزن : <span style={order.available > 10 ? { color: "green" } : { color: "red" }}>      {order.available} </span></h4></center></Col>

                                                                </Row>

                                                                <Table dir='rtl' responsive bordered hover >
                                                                    <thead style={role == -1 ? {} : { display: "none" }}>
                                                                        <tr>
                                                                            <th>اليوم</th>
                                                                            <th> طلب جديد </th>
                                                                            <th>الانتظار </th>
                                                                            <th> قيد التوصيل</th>
                                                                            <th> لايرد </th>
                                                                            <th> الراجع </th>
                                                                            <th> الملغي </th>
                                                                            <th> معدل السعر </th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody style={role == -1 ? {} : { display: "none" }}>
                                                                        {order.items.map((item, index) => (
                                                                            <tr key={index}>

                                                                                <td style={{ textAlign: 'center' }}>{item.day}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.neworder}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.wattting}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.onprocess}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.miss}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.rejected}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.cancel}</td>
                                                                                <td style={{ textAlign: 'center' }}>{item.price}</td>





                                                                            </tr>
                                                                        ))}


                                                                    </tbody>
                                                                </Table>
                                                                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                                                                    <span> تاريخ البدا :{order.start_at}</span>
                                                                    <span>الميزانية :{order.baged}</span>

                                                                    <span> تاريخ الانتهاء :{order.off_at}</span>
                                                                    <br></br>
                                                                    <Component initialState={{ isShown: false }}>
                                                                        {({ state, setState }) => (
                                                                            <Pane>
                                                                                <Dialog
                                                                                    isShown={state.isShown}
                                                                                    title=" تعديل"
                                                                                    intent="success"
                                                                                    onCloseComplete={() => setState({ isShown: false })}
                                                                                    confirmLabel="تعديل"
                                                                                    cancelLabel="الغاء"
                                                                                    onConfirm={() => {
                                                                                        setState({ isShown: false })
                                                                                        this.off(order.id)
                                                                                    }}
                                                                                >
                                                                                    <div>

                                                                                        <div id={'ContinerInPut'}>
                                                                                            <label>وقت انتهاء الحملة</label>
                                                                                            <input autoComplete='off' type='date' placeholder='اسم القسم' id='InputTExtDash111'
                                                                                                onChange={(e) => this.setState({ editOff: e.target.value })} />
                                                                                        </div>

                                                                                    </div>
                                                                                </Dialog>
                                                                                <Button style={{ cursor: 'pointer' }}
                                                                                    onClick={() => { setState({ isShown: true }) }} >تعديل </Button>
                                                                            </Pane>

                                                                        )}
                                                                    </Component>

                                                                </div>


                                                            </div>

                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}



                                        </Row>

                                    </div>
                                )
                                }

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;