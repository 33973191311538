import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Button, Nav ,Tabs ,Tab} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Paragraph, Card, Heading } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Info from '@material-ui/icons/Info';
import ReplayIcon from '@material-ui/icons/Replay';
import FileUpload from '@material-ui/icons/SyncRounded';

import SyncDisabledRounded from '@material-ui/icons/SyncDisabledRounded';

import CategoryIcon from '@material-ui/icons/Category';
import Error from '@material-ui/icons/ReportProblem';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import { Link } from 'react-router-dom';

const columns = [

    { field: "info", name: "المزيد", options: { width: 200, filter: false, sort: false, } },
    { field: "status", name: "الحالة", options: { width: 200, filter: true, sort: false, } },

    { field: "type", name: "النوع", options: { width: 200, filter: true, sort: false, } },
    { field: "add_at", name: " تاريخ الشراء", options: { width: 200, filter: false, sort: false, } },
    { field: "createdAt", name: "تاريخ الادخال", options: { filter: false, sort: true, } },
    { field: "t_amount", name: "مبلغ الكلي", options: { filter: false, sort: true, } },
    { field: "name", name: "اسم التاجر", options: { filter: true, sort: true, } },
    { field: "id", name: "رقم القائمة", options: { width: 200, filter: false, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 50,

    filter: true,

    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: []

        }

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/biils/all/`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.bills);
                let items = res.data.bills

                for (let i = 0; i < items.length; i++) {

                    let obj = {
                        i: i,
                        name: items[i].suppliers.name,
                        id: items[i].id,
                        add_at: moment(items[i].add_at).format('DD/MM/YYYY'),
                        type: items[i].type,
                        status: items[i].status,
                        t_amount: items[i].t_amount,

                        createdAt: moment(items[i].createdAt).format('DD/MM/YYYY'),
                        // items: <Link to={`/Dayle_items?id=${items[i].id}`}><CategoryIcon style={{ color: '#ffc107' }} /></Link>,
                        // send: "",
                        info: <Link to={`bill?id=${items[i].id}`}><Info /></Link>,
                        // print: <a href={`https://pages.enfirad.com/Print2/${items[i].id}`}><ReplayIcon style={{ color: 'green' }} /></a>
                    };

                    if (items[i].type == 0) {
                        obj.type = "داخلي"
                    } else {
                        obj.type = "خارجي"
                    }

                    if (items[i].status == 0) {
                        obj.status = 'غير مدققة'
                    } else if (items[i].status == 1) {
                        obj.status = 'انتظار'
                    } else if (items[i].status == 2) {
                        obj.status = 'تم الادخال الى المخزن'
                    }
                    else if (items[i].status == 3) {
                        obj.status = 'ملغية'
                    }


                    arr.push(obj);
                }


                this.setState({
                    orders: arr, spin: false,

                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };


    update_list(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.post(host + `dashbord/list/send/${id}`, { headers: header })
            .then(response => {

                toast('تم ارسال القائمة', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount()
            })
            .catch(error => { console.log(error) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >




                                <div id='navDashContiner'>
                                    <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => window.location.href = "/newbill"}> انشاء فاتورة</div>
                                </div>
                                <div>
                                    <Nav variant="tabs" defaultActiveKey="/home">
                                        <Nav.Item>
                                            <Nav.Link href="/home">Active</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-1">Option 2</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="disabled" disabled>
                                                Disabled
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;