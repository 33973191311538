import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Form } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/LocalPrintshop';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import * as moment from "moment-timezone";
const cookies = new Cookies();
const columns = [
    { field: "print", name: "اعادة طباعة", options: { filter: false, sort: false, search: false } },
    { field: "createdAt", name: "تاريخ الاضافة", options: { filter: false, sort: true, search: true } },

    { field: "notes", name: "ملاحظات", options: { filter: true, sort: true, search: true } },
    { field: "battery", name: "نسبة البطارية", options: { filter: false, sort: true, search: true } },
    { field: "sn", name: "S/N", options: { filter: false, sort: true, search: true } },
    { field: "name", name: "الجهاز", options: { filter: false, sort: true, search: true } }

    ,

    { field: "id", name: "#", options: { filter: false, } },
];

const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    search: true,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    },

}


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
        }
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/Devices/all`, { headers: header })
            .then(res => {
                let arry = []
                for (let index = 0; index < res.data.devices.length; index++) {
                    let obj = {
                        id: res.data.devices[index].id,
                        name: res.data.devices[index].name,
                        sn: res.data.devices[index].sn,

                        createdAt: moment(res.data.devices[index].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD'),
                        notes: res.data.devices[index].notes,
                        battery: res.data.devices[index].battery,
                        print: <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }
                        }
                            onClick={() => {

                                window.location.href = `/Device?id=${res.data.devices[index].id}`;

                            }
                            } />,
                    }

                    arry.push(obj)
                }
                this.setState({
                    Category: arry, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    EditCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", this.state.nameCate);
        formData.append("is_count", 0);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/category/${id}`, method: "PUT", data: formData, headers: header })
            .then(response => {
                this.setState({ image: '' })
                this.componentDidMount()
                toast('تم التعديل بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/category/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    print() {

        var printContents = document.getElementById('divcontents').innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        window.location.reload();


    }
    AddCate() {
        let name = document.getElementById('name').value;

        let sn = document.getElementById('sn').value;
        let battery = document.getElementById('battery').value;

        let notes = document.getElementById('notes').value;
        let order = document.getElementById('order').value;
        let check = document.getElementById('check').checked;

        console.log(order);
        console.log(check);

        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", name);
        formData.append("sn", sn);
        formData.append("battery", battery);
        formData.append("notes", notes);
        formData.append("order", order);
        if (check) {
            formData.append("check", 1);

        }

        axios({ url: host + `dashbord/Devices/new`, method: "POST", data: formData, headers: header })
            .then(response => {


                this.componentDidMount();
                console.log(response.data.devices.id);

                window.location.href = `/Device?id=${response.data.devices.id}`;
                toast('تم اضافة الجهاز بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data.message) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Component initialState={{ isShown: false }}>
                                            {({ state, setState }) => (
                                                <React.Fragment>
                                                    <SideSheet
                                                        isShown={state.isShown}
                                                        position={Position.LEFT}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <div margin={40}>
                                                            <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة جهاز جديد</div>
                                                            <div id='inputAdd2Continer' >
                                                                <div id={'ContinerInPut'} >
                                                                    <label> اسم الجهاز </label>
                                                                    <input autoComplete='off' className='InputTExtDash111' type='text' placeholder='اسم الجهاز ' id='name'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>  SN </label>
                                                                    <input autoComplete='off' className='InputTExtDash111' type='text' placeholder='SN' id='sn'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>  نسبة البطارية </label>
                                                                    <input autoComplete='off' className='InputTExtDash111' type='number' placeholder='البطارية' id='battery'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>ملاحظات</label>
                                                                    <input autoComplete='off' className='InputTExtDash111' type='text' placeholder='ملاحظات' id='notes'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>رقم الوجبة</label>
                                                                    <input autoComplete='off' className='InputTExtDash111' type='text' placeholder='رقم الوجبة' id='order'
                                                                        onChange={(e) => this.setState({ nameCate: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>غير شغال</label>
                                                                    <Form.Check // prettier-ignore
                                                                        type={"checkbox"}
                                                                        id={'check'}

                                                                    />
                                                                </div>


                                                            </div>
                                                            <div id='btnAddContiner'>
                                                                <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                    this.AddCate()
                                                                    setState({ isShown: false })
                                                                }}>اضافة</div>
                                                            </div>

                                                        </div>
                                                    </SideSheet>
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة جهاز</div>
                                                </React.Fragment>
                                            )}
                                        </Component>

                                    </div>


                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            title="الاجهزة"
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;