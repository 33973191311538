import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment-timezone";
import Login from '../common/login';
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            items_price_sum: '',
            items_dw_price_sum: '',
            items_saffe: '',
            orders_price: '',
            Spending_price: '',
            component_orders_count: '',
            orders_count: '',
            da5al: '',
        }
    }
    Orders() {
        var date1 = moment(document.getElementById('date1').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        var date2 = moment(document.getElementById('date2').value, 'DD/MM/YYYY').format('MM/DD/YYYY');
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/reports/all/?from=${date1}&to=${date2}`, { headers: header })
            .then(res => {
                console.log(res.data.data);
                this.setState({
                     spin: false, 
                     items_price_sum:res.data.data.items_price_sum,
                     items_dw_price_sum:res.data.data.items_dw_price_sum,
                     items_saffe:res.data.data.items_saffe,
                     orders_price:res.data.data.orders_price,
                     Spending_price:res.data.data.Spending_price,
                     component_orders_count:res.data.data.component_orders_count,
                     orders_count:res.data.data.orders_count,
                     da5al:res.data.data.da5al,
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='ContinerReportDate'>
                                    <Row id='RowReportDate'>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <span>من : &nbsp;</span>
                                            <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <span>الى : &nbsp;</span>
                                            <DatePicker id='date2'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>
                                {!this.state.serch ? null : (
                                    <div>
                                        <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>
                                            <Col id='colInfoOffice' sm={12} lg={6}>
                                                <div id='CardInfoOfficeContiner' style={{ backgroundColor: '#85C1E9' }}>
                                                    <h6>المحفظة</h6>
                                                    <h6>{this.state.da5al.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                            </Col>
                                            <Col id='colInfoOffice' sm={12} lg={6}>
                                                <div id='CardInfoOfficeContiner' style={{ backgroundColor: '#F1948A' }}>
                                                    <h6>المصاريف</h6>
                                                    <h6>{this.state.Spending_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div id='lineRepo'>
                                            <div id='lineRepo1' />
                                            <h5 style={{ textAlign: 'center' }}> المنتجات</h5>
                                            <div id='lineRepo1' />

                                        </div>
                                        <Row style={{ direction: 'rtl', padding: 0, margin: 10, marginTop: 20 }}>
                                            <Col id='colInfoOffice' sm={12} md={6} lg={3}>
                                                <div id='CardInfoOfficeContiner' style={{ backgroundColor: '#dc5d35e0' }}>
                                                    <h6>مجموع المبيعات </h6>
                                                    <h6>{this.state.items_price_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                            </Col>
                                            <Col id='colInfoOffice' sm={12} md={6} lg={3}>
                                                <div id='CardInfoOfficeContiner' style={{ backgroundColor: '#85C1E9' }}>
                                                    <h6>صافي الربح</h6>
                                                    <h6>{this.state.items_saffe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                            </Col>
                                            <Col id='colInfoOffice' sm={12} md={6} lg={3}>
                                                <div id='CardInfoOfficeContiner' style={{ backgroundColor: '#F1948A' }}>
                                                    <h6>التكاليف</h6>
                                                    <h6>{this.state.items_dw_price_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h6>
                                                </div>
                                            </Col>
                                           
                                            <Col id='colInfoOffice' sm={12} md={6} lg={3}>
                                                <div id='CardInfoOfficeContiner' style={{ backgroundColor: '#F7DC6F' }}>
                                                    <h6>عدد المبيعات</h6>
                                                    <h6>{this.state.component_orders_count}</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;