import React from 'react';
import Context from '../../assets/js/Context';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { NavLink } from 'react-router-dom';
import Category2 from './category2';
import Shop2 from './shop2';
import Orders from './orders';
import AllProdect from './AllProdect';
// import Login from '../common/login';
import { withRouter } from 'react-router-dom'
// import host from '../../assets/js/Host';
// import axios from 'axios';
import Cookies from "universal-cookie";
const cookies = new Cookies();


class ResponsiveDrawer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileOpen: false,
        }
    }
    renderPage() {
        if (this.props.match.path === '/Category2') {
            return (<Category2 />)
        }
        else if (this.props.match.path === '/Shop2') {
            return (<Shop2 />)
        }
        else if (this.props.match.path === '/AllProdect') {
            return (<AllProdect />)
        }
        else if (this.props.match.path === '/Orders') {
            return (<Orders />)
        }


    }
    render(props) {
        const { container } = this.props;

        const handleDrawerToggle = () => {
            this.setState({ mobileOpen: !this.state.mobileOpen });
        };

        const drawer = (
            <div id='BTNSlideContiner'>
                <div>

                    <div id='toolbarSide' />
                    {/* < NavLink activeClassName='ActiveSliderBTN' to='/Orders' >
                    <div>
                        <ListAltIcon id='iconActive' style={{fontSize:25}} />
                        <article id='BTNSlideName'> الطلبات </article>
                    </div>
                </NavLink> */}

                    <NavLink activeClassName='ActiveSliderBTN' to='/Category2' >
                        <div id='BTNSlide'>
                            <AllInboxIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>المنتجات </article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/AllProdect' >
                        <div id='BTNSlide'>
                            <AllInboxIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>جميع المنتجات </article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/Orders' >
                        <div id='BTNSlide'>
                            <ListAltIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>الطلبات </article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/Shop2' >
                        <div id='BTNSlide'>
                            <ShoppingBasketIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>سلة المنتجات </article>
                        </div>
                    </NavLink>

                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/Drivers' >
                    <div>
                        <DriveEtaIcon id='iconActive' style={{fontSize:25}} />
                        <article id='BTNSlideName'>السواق</article>
                    </div>
                </NavLink> */}

                    <NavLink activeClassName='ActiveSliderBTN' to='/' onClick={() => {
                        cookies.remove("officeToken")
                    }}>

                        <article id='BTNSlide'>
                            <MeetingRoomIcon id='iconActive' style={{ fontSize: 25 }} />
                            <article id='BTNSlideName'>تسجيل الخروج</article>
                        </article>
                    </NavLink>

                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {/* <img src={require('../../assets/img/logo.png')} alt='logo' id='logoSideBar' /> */}
                </div>
            </div>
        );
        return (
            <Context.Consumer>
                {ctx => {
                    // if (!ctx.value.Redirect && ctx.value.office_type === 0 && (ctx.value.profile.role === 0 || ctx.value.profile.role === 1)) {
                    return (
                        <div id='rootSidebar'>

                            <Toolbar id='NavContinerApp'>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    id='menuButtonSidebarnew'
                                >
                                    <MenuIcon style={{ fontSize: 25 }} />
                                </IconButton>
                            </Toolbar>
                            <nav id='drawerSidebar' aria-label="mailbox folders">

                                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                                <Hidden smUp implementation="css">

                                    <Drawer
                                        container={container}
                                        variant="temporary"
                                        anchor='right'
                                        open={this.state.mobileOpen}
                                        onClose={handleDrawerToggle}
                                        id='drawerPaper'
                                        ModalProps={{
                                            keepMounted: true, // Better open performance on mobile.
                                        }}

                                    >
                                        <div id='sideBarbgc2' style={{ height: '100vh' }}>

                                            {drawer}
                                        </div>
                                    </Drawer>
                                </Hidden>

                                <Hidden xsDown implementation="css">

                                    <Drawer
                                        anchor='right'
                                        id='drawerPaper'
                                        variant="permanent"
                                        open

                                    >
                                        <div id='sideBarbgc2' style={{ paddingTop: 10, height: '100vh' }}>

                                            {drawer}
                                        </div>
                                    </Drawer>
                                </Hidden>
                            </nav>
                            <main id='contentSidebar'>
                                <div id='toolbarSide' />
                                <div id='HomeContiner'>
                                    <div id='HomeContiner1'>
                                        {this.renderPage()}
                                    </div>
                                </div>
                            </main>
                        </div>
                    )
                    // }
                    // else if (ctx.value.spin) {
                    //     return (
                    //         <div id='NotFondStyle' style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    //             <Lottie
                    //                 options={{
                    //                     animationData: animation
                    //                 }}
                    //             />
                    //         </div>
                    //     )
                    // }
                    // else {
                    //     return (
                    //         <Login />
                    //     )
                    // }
                }
                }
            </Context.Consumer>
        )

    }



}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};
export default withRouter(ResponsiveDrawer)