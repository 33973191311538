import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { Pane, Dialog, Button, Label, Textarea, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import LocalPrintshopIcon from '@material-ui/icons//LocalPrintshop';
import { Form } from 'react-bootstrap';


// import { Form } from 'react-bootstrap';

// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import { createMuiTheme } from '@material-ui/core/styles';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import DataTable from 'react-data-table-component';


// import { JsonToExcel } from "react-json-to-excel";




// const mystyle = {
//     width: "33px",
//     height: "55",
// };

// const columns = [
// const Checkbox2 = React.forwardRef(({ onClick, ...rest }, ref) => {
//     return (
//         <>
//             <div className="form-check pb-5" id="divTable" style={{ backgroundColor: '', display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
//                 <Form.Check
//                     height={66}
//                     style={mystyle}
//                     ref={ref}
//                     onClick={onClick}
//                     {...rest}
//                 />

//             </div>
//         </>
//     )
// })

//     { field: "notes2", name: "المادة", options: { filter: true, sort: false, } },
//     { field: "notes", name: "ملاحظات", options: { filter: true, sort: true, } },

//     { field: "price", name: "السعر", options: { width: 25, filter: true, sort: true, } },
//     { field: "address", name: "العنوان", options: { width: 350, filter: true, sort: true, } },
//     { field: "phone", name: "رقم الهاتف", options: { filter: true, sort: true, } },

//     { field: "id", name: "رقم الوصل", options: { width: 150, filter: true, sort: false, } },
//     //   { field: "i", name: "#", options: { filter: true, sort: false, } },

// ];
const customStyles = {

    rows: {
        style: {
            minHeight: '72px', // override the row height
            border: 'solid 1px black'

        },
    },

    headCells: {
        style: {
            paddingLeft: '8px', // override the cell padding for head cells
            paddingRight: '8px',
            justifyContent: 'center',
            ' font-size': '25px',
            'font-weight': 'bold',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            border: 'solid 1px black',
            ' font-size': '20px',
            'font-weight': 'bold',
            justifyContent: 'center',
        },
    },
};


const columns = [

    {
        name: 'المسخدم',
        selector: row => row.user,
   

    },
    {
        name: 'الحالة',
        selector: row => row.status,
     

    },
    {
        name: 'التاريخ',
        selector: row => row.createdAt,
        sortable: true,
    
        cell: row => <div style={{ textAlign: "center", fontSize: 15 }}>{moment(row.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</div>

    },
    {
        name: ' الطلب',
        selector: row => row.orderId,
        sortable: true,
  
    },
];

const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            shiper: [],
            id: '',
            ids: [],
            company: 'none',
            selectedData: [],
            price: 0
        }

        this.Orders()

    }






    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                // root: {
                //     //   backgroundColor: "#FF0000",
                //     textAlign: 'center',
                //     fontSize: '25px',
                //     fontWeight: 'bold',
                //     border: '2px solid'

                // },

            },

            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                // head: {
                //     // backgroundColor: "#FF0000",
                //     textAlign: 'center',
                //     fontWeight: 'bold',
                //     fontSize: '25px',
                //     border: '2px solid'
                // }
            },

        }
    })

    Orders() {
        console.log('here');
        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/dash/logs`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.logs
   


                this.setState({
               
                    orders: items,
                    spin: false,
        
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {


        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };


    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >




                                <br />


                                <div>
                                    <div className='DataTableContiner'>
                                        <DataTable title="سجل لوحة التحكم" highlightOnHover
                                            pointerOnHover direction={'center'}
                                            customStyles={customStyles}
                                            columns={columns}
                                            data={this.state.orders}
                                            //   selectableRows

                                            onSelectedRowsChange={({ allSelected, selectedCount, selectedRows }) => {
                                                //   console.log(selectedRows);


                                            }}
                                        //    selectableRowsComponent={Checkbox2}
                                        // onRowClicked={(row) => {

                                        //     this.handleRowClicked(row)
                                        // }}

                                        />

                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;