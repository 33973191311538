import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Spinner, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as moment from "moment";
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import { Row, Col } from 'react-bootstrap';
import Login from './login';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import BarcodeReader from 'react-barcode-reader'
const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
const cookies = new Cookies();
function print() {

    var printContents = document.getElementById('ifmcontentstoprintshop').innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
}

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            items: [],
            spin: false,
            valueCount: 1,
            spinerBtn: false,
            shop: [],
            total: '',
            CustomerOption: [],
            idCustomerSelect: '',
            nameuser: '',
            SelectHidden: false,
            DiscontCustomer: 0,
            name:'',
            phone:'',
            address:'',
        }
    }
    handleError(err) {
        toast('حاول مره اخرى', {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
        });
    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `users/ccart/`, { headers: header })
            .then(res => {
                console.log(res.data.data.cart);
                this.setState({
                    shop: res.data.data.cart.items,
                    total: res.data.data.cart.price,
                    spin: true
                });
            })
            .catch(error => { console.log(error.response) })
    }

    sendOrder() {
        axios.post(host + `users/component/order/new`,
            qs.stringify({
                name: this.state.name,
                phone:this.state.phone,
                address:this.state.address,
                price: this.state.total,
                new_price: this.state.total -  this.state.DiscontCustomer,
                discount: this.state.DiscontCustomer
            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser")
            }
        })
            .then(response => {
                this.componentDidMount()
                toast("تم الارسال", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => {
                toast(error.response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    scan(barcode) {
        axios.post(host + `users/cart/barcode/add`,
            qs.stringify({
                barcode: barcode,
            }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser")
            }
        })
            .then(response => {
                this.componentDidMount()
                toast("تمت الاضافة", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => {
                toast('المنتج غير  متوفر', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                 

                                    <BarcodeReader
                                        onError={this.handleError}
                                        onScan={(e) => {
                                            this.scan(e)
                                        }}
                                    />
                                    <p>{this.state.result}</p>
                                    <Row style={{ direction: 'rtl', margin: 0, paddingTop: 170 }}>
                                        {this.state.shop.map(item =>
                                            <Col key={item.id} id='ColSettingDash' sm={12} md={6} lg={4} xl={3}>
                                                <Component initialState={{ spin: false }}>
                                                    {({ state, setState }) => (
                                                        <div id='CardShopContinerr'>
                                                            <div id='PriceCardCOntiner'>{item.price * item.count} IQD</div>
                                                            <div id='CountCardCOntiner'>{item.count}</div>
                                                            <div id='ImageItemContiner'>
                                                                <img src={host + 'files/' + item.photo} alt='sta' style={{ width: 250, height: 140, borderRadius: 10 }} />
                                                                <div id='shadoUpCardItem' />
                                                            </div>
                                                            <div id='contintItemCard'>
                                                                <h6>{item.name}</h6>
                                                                {!state.spin ? (<div className='BtnADDTOINitem' onClick={() => {
                                                                    setState({ spin: true })
                                                                    var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
                                                                    axios.delete(host + `users/ccart/add/${item.id}`, { headers: header })
                                                                        .then(response => {
                                                                            toast('تم الغاء الطلب ', {
                                                                                position: "bottom-center",
                                                                                autoClose: 5000,
                                                                                hideProgressBar: false,
                                                                                closeOnClick: true,
                                                                                pauseOnHover: true,
                                                                                draggable: true
                                                                            });
                                                                            this.componentDidMount();
                                                                            ctx.action.RefreshComponent();
                                                                            setState({ spin: false })
                                                                        })
                                                                        .catch(error => {
                                                                            console.log(error.response.data.message)
                                                                            setState({ spin: false })
                                                                        })
                                                                }}>الغاء</div>) : (
                                                                        <div className='BtnADDTOINitem' ><Spinner size={24} /></div>
                                                                    )}
                                                            </div>

                                                        </div>
                                                    )}
                                                </Component>
                                            </Col>
                                        )}

                                    </Row>

                                    <div id='ifmcontentstoprintshop'>
                                        <div id='hederPrint'>
                                            <div style={{ fontSize:40,fontWeight:600, textAlign: 'right', marginRight: 0 }}>  oilxlio </div>
                                            <img id='logoPrint' style={{ marginLeft: 50, width: 250 }} src={require('../../assets/img/print.jpg')} alt='logo' />
                                        </div>
                                        <div style={{ width: 800, fontSize: 20, textAlign: 'right', marginRight: 30, direction: 'rtl', marginTop: 10 }
                                        }>التاريخ : {moment(this.state.date).format('DD/MM/YYYY LT')}</div>
                                        <div style={{ width: 800, fontSize: 20, textAlign: 'right', marginRight: 30, direction: 'rtl', marginTop: 10 }
                                        }>اسم العميل :  ({this.state.nameuser}) </div>

                                        <div style={{ width: 800, marginTop: 40 }}>
                                            <div style={{ fontSize: 25, fontWeight: 'bold', textAlign: 'center' }}>فاتورة</div>
                                            <div id='hederTablePrint'>
                                                <table id='students' style={{ marginBottom: 10 }}>
                                                    <thead>
                                                        <tr>
                                                            <th>ت</th>
                                                            <th>المادة</th>
                                                            <th>العدد</th>
                                                            <th>سعر المفرد</th>
                                                            <th>المبلغ</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {this.state.shop.map((item, i) => (
                                                            <tr key={i}>
                                                                <td style={{ width: 40 }} >{i + 1}</td>
                                                                <td  >{item.name}</td>
                                                                <td style={{ width: 60 }} >{item.count}</td>
                                                                <td  >{item.price}</td>
                                                                <td  >{(item.price * item.count)}</td>
                                                            </tr>

                                                        ))}
                                                    </tbody>

                                                </table>

                                            </div>
                                            <div id='hederPrint' style={{ direction: 'rtl', marginTop: -10, marginLeft: 30, paddingRight: 20 }}>
                                                <div style={{ marginLeft: 50, fontSize: 20 }}> الخصم</div>
                                                <div style={{ fontSize: 20 }}>{this.state.DiscontCustomer} % </div>
                                            </div>
                                            <div id='hederPrint' style={{ direction: 'rtl', marginTop: -10, marginLeft: 30, paddingRight: 20 }}>
                                                <div style={{ marginLeft: 50, fontSize: 20 }}> المبلغ الكلي</div>
                                                <div style={{ fontSize: 20 }}>{Number(this.state.total) - Number(this.state.total) * Number(this.state.DiscontCustomer) * 0.01} IQD</div>
                                            </div>

                                        </div>

                                    </div>
                                    <div id='PrintAndTotalContiner'>
                                        <div id='totalShopCOntiner'>  المجموع : {this.state.total} IQD</div>

                                        <div id='BTNSendAndPrintContiner'>
                                            <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title='ارسال الطلب'
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="ارسال"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                this.sendOrder()
                                                                setState({ isShown: false })
                                                            }}
                                                        >
                                                            <div>
                                                                <div id='inputAddContiner'>
                                                                <div id={'ContinerInPut'} >
                                                                        <label>اسم الزبون</label>
                                                                        <input autoComplete='off' type='text' placeholder='اسم الزبون' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ name: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>رقم الهاتف </label>
                                                                        <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ phone: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>العنوان </label>
                                                                        <input autoComplete='off' type='text' placeholder='العنوان' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ address: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>نسبه الخصم </label>
                                                                        <input autoComplete='off' type='number' placeholder='نسبه الخصم' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ DiscontCustomer: e.target.value })} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Dialog>
                                                        <div id='BTNSendAndPrint' onClick={() => { setState({ isShown: true }) }}>ارسال</div>
                                                    </Pane>
                                                )}
                                            </Component>

                                            <Component initialState={{ isShown: false }}>
                                                {({ state, setState }) => (
                                                    <Pane>
                                                        <Dialog
                                                            isShown={state.isShown}
                                                            title=' طباعة و ارسال الطلب'
                                                            onCloseComplete={() => setState({ isShown: false })}
                                                            confirmLabel="طباعة"
                                                            cancelLabel="الغاء"
                                                            onConfirm={() => {
                                                                this.sendOrder()
                                                                print()
                                                                setState({ isShown: false })
                                                            }}
                                                        >
                                                            <div>
                                                                <div id='inputAddContiner'>
                                                                <div id={'ContinerInPut'} >
                                                                        <label>اسم الزبون</label>
                                                                        <input autoComplete='off' type='number' placeholder='اسم الزبون' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ name: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>رقم الهاتف </label>
                                                                        <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ phone: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>العنوان </label>
                                                                        <input autoComplete='off' type='number' placeholder='العنوان' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ address: e.target.value })} />
                                                                    </div>
                                                                    <div id={'ContinerInPut'} >
                                                                        <label>نسبه الخصم </label>
                                                                        <input autoComplete='off' type='number' placeholder='نسبه الخصم' id='InputTExtDash1'
                                                                            onChange={(e) => this.setState({ DiscontCustomer: e.target.value })} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Dialog>
                                                        <div id='BTNSendAndPrint' onClick={() => { setState({ isShown: true }) }}>طباعة وارسال</div>
                                                    </Pane>
                                                )}
                                            </Component>
                                        </div>

                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="sweet-loading">
                                        <ClipLoader
                                            css={override}
                                            size={100}
                                            color={"#fff"}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;