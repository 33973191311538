import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

import InfoIcon from '@material-ui/icons/Info';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import qs from 'qs';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import * as moment from "moment-timezone";
import Login from '../common/login';
import DesktopMacIcon from '@material-ui/icons/DesktopWindows';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    // { field: "add_date", name: " ", options: { filter: true, sort: false, } },

    { field: "info", name: "التفاصيل", options: { filter: true, sort: false, } },

    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let categories = []
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],

            spin: false,
            serch: false,
            total: ''
        }


        axios.get(host + `dashbord/category`)
            .then(res => {
                let arr = [];

                for (let i = 0; i < res.data.data.categories.length; i++) {

                    let obj = {
                        id: res.data.data.categories[i].id,
                        name: res.data.data.categories[i].name,

                    };
                    categories.push(obj);
                }

                this.setState({
                    orders: arr, spin: false, total: res.data.data.amount
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/spending/${id}?`, { headers: header })
            .then(response => {
                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.Orders();
            })
            .catch(error => { console.log(error) })
    }
    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/users/all`, { headers: header })
            .then(res => {
                let arr = [];
                for (let i = 0; i < res.data.data.users.length; i++) {

                    let obj = {
                        id: res.data.data.users[i].id,
                        name: res.data.data.users[i].name,
                        phone: res.data.data.users[i].phone,
                        Percentage: res.data.data.users[i].Percentage,
                        page_name: res.data.data.users[i].page_name,
                        info:<a  href={`/UserReprots?id=${ res.data.data.users[i].id}`}><InfoIcon style={{ color: '#007bff', cursor: 'pointer' }} /></a> ,
                

                    };
                    arr.push(obj);
                }
                this.setState({
                    orders: arr, spin: false, total: res.data.data.amount
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                <a  href={`/EmployeeList`}><KeyboardBackspace style={{ color: '#fff', cursor: 'pointer',fontSize:35 }} /></a>

                                </div>


                                <div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;