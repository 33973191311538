import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CategoryIcon from '@material-ui/icons/Category';
import MenuIcon from '@material-ui/icons/Menu';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined';
import LocalPrintshopOutlined from '@material-ui/icons/LocalPrintshopOutlined';
import HourglassTopIcon from '@material-ui/icons/WatchLater';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LocalShipping from '@material-ui/icons/LocalShipping';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Replacement from '../dash/replacement';
import ShippingcompaniesList from '../dash/shippingCompaniesList';

import Safe from '../dash/safe';

import UncheckedBill from '../dash/uncheckedBill';
import Unavailable from '../dash/unavailable';


import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart';



import UsersList from '../dash/userList';
import Calsslog from '../dash/callsLogs';

import RejctedList from '../dash/rejectedLIst';
import LogsCan from '../dash/LogsCan';
import Settings from '@material-ui/icons/Settings';
import Close from '@material-ui/icons/Close';
import CallIcon from '@material-ui/icons/Call';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';

import AttachMoney from '@material-ui/icons/AttachMoney';


import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import Login from '../common/login';
import Cate2 from '../dash/cate2';
import FromOrders from '../dash/fromOrders';
import Wattingselles from '../dash/wattingReports.';
import Miss from '../dash/miss';
import Apanding_order from '../dash/apanding_order';
import Print_list from '../dash/print_list';
import CancelledOrders from '../dash/cancelledOrders';
import SettingDash from '../dash/setting';
import DoneTable from '../dash/doneTable';
import Sheet from '../dash/sheet';
import Check from '../dash/check';
import Print from '../dash/Print';
import M3alk from '../dash/m3alk';
import SuppliersList from '../dash/SuppliersList';


import DoneReports from '../dash/doneOrdersReports';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CalledOrders from '../dash/calledOrders';
import FromOrdersWatting from '../dash/fromOrdersWaittng';
import Sells from '../dash/sells_reports';
import DelveryReports from '../dash/delveryReports';
import Expenses from '../dash/expenses';
import Order2 from '../dash/Order2';
import Setting from '../dash/setting';
import ReportIcon from '@material-ui/icons/Report';
import SearchIcon from '@material-ui/icons/Search';
import Group from '@material-ui/icons/Group';
import StorefrontIcon from '@material-ui/icons/Storefront';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import BarChartIcon from '@material-ui/icons/BarChart';

import AlarmOn from '@material-ui/icons/PhoneDisabled';


import Loop from '@material-ui/icons/Loop';

import axios from 'axios';
import host from '../../assets/js/Host';
import Badge from 'react-bootstrap/Badge';
import ManageHistory from '@material-ui/icons/Airplay';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Report from '../dash/report';
import DoneIcon from '@material-ui/icons/Done';
import RejectIcon from '@material-ui/icons/Cancel';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DoneOrder from '../dash/DoneOrder';
import RejectOrder from '../dash/RejectOrder';
import Cookies from "universal-cookie";
import Search from '../dash/Search';
import Logs from '../dash/ordersLogs';
import SupportAgent from '@material-ui/icons/PhoneForwarded';
import AdsReprots from '../dash/ads_reports';
import BillsList from '../dash/billsList';

let billsCount = 0;


const cookies = new Cookies();
const drawerWidth = 240;
const role = Number(localStorage.getItem('role'))
const useStyles = makeStyles(theme => ({
    root: {
        direction: 'rtl',
        display: 'flex',
        paddingTop: 0,
    },
    drawer: {

        paddingTop: 0,
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    BTNSlideContiner: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        //   alignItems: 'center',
        justifyContent: 'space-between',
    },
    menuButton: {
        marginRight: theme.spacing(2),
        zIndex: 11,
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    BTNSlide: {
        // width:drawerWidth,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(4),
        marginBottom: 10,
        color: '#fff',
        height: 40,
        cursor: 'pointer',
        '&:hover': {
            color: '#83529d!important',
            transitionDuration: '.3s',
            transitionTimingFunction: 'linear',
            textDecoration: 'none',
        },

    },
    BTNSlideName: {
        marginRight: 15,
        fontSize: 18
    },
    toolbar: {
        marginTop: -24,
    },
    drawerPaper: {
        backgroundColor: '#094a8f',
        width: drawerWidth,
        direction: 'rtl',
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },

}));

function ResponsiveDrawer(props) {
    const { container } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            {/* <div id='ImageSideBare'>
                <img src={require('../../assets/img/side.jpg')} alt='side' style={{ width: 238, height: '100vh' }} />
            </div> */}
            <div style={{ overflow: 'scroll' }} className={classes.BTNSlideContiner}>

                <div id="continerBTNSideBar">
                    <NavLink to='/'>
                        {/* <img src={require('../../../assets/img/logo.png')} alt='logo' style={{width:180,marginLeft:40,marginBottom:50}} /> */}
                    </NavLink>
                    <div className={classes.toolbar} />
                    < NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/Cate2' >
                        <div id='BTNSIDEbar'>
                            <CategoryIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>المنتجات</article>
                        </div>
                    </NavLink>

                    < NavLink style={role != -1 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/safe' >
                        <div id='BTNSIDEbar'>
                            <AttachMoney id='iconActive' />
                            <article className={classes.BTNSlideName}>القاصة</article>
                        </div>
                    </NavLink>

                    {/* < NavLink activeClassName='ActiveSliderBTN' to='/EmployeeList' >
                        <div id='BTNSIDEbar'>
                            <Group id='iconActive' />
                            <article className={classes.BTNSlideName}>ادارة الموظفين</article>
                        </div>
                    </NavLink> */}

                    <NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/Expenses' >
                        <div id='BTNSIDEbar'>
                            <TrendingDownIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>المصاريف</article>
                        </div>
                    </NavLink>

                    <NavLink activeClassName='ActiveSliderBTN' to='/print' >
                        <div id='BTNSIDEbar'>
                            <LocalPrintshopIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>طباعة وصولات</article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/unavailable' >
                        <div id='BTNSIDEbar'>
                            <RemoveShoppingCart id='iconActive' />
                            <article className={classes.BTNSlideName}>الغير موجود </article>
                        </div>
                    </NavLink>





                    <NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/suppliersList' >
                        <div id='BTNSIDEbar'>
                            <StorefrontIcon id='iconActive' />
                            <article className={classes.BTNSlideName}> التجار</article>
                        </div>
                    </NavLink>

                    <NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/shippingcompaniesList' >
                        <div id='BTNSIDEbar'>
                            <LocalShippingIcon id='iconActive' />
                            <article className={classes.BTNSlideName}> شركات التوصيل</article>
                        </div>
                    </NavLink>



                    <NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/billsList' >
                        <div id='BTNSIDEbar'>
                            <ShoppingCart id='iconActive' />
                            <article className={classes.BTNSlideName}> المشتريات <Badge style={{ background: "red", color: "#FFF" }} pill bg="danger">{billsCount}</Badge> </article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/printlist' >
                        <div id='BTNSIDEbar'>
                            <LocalPrintshopIcon id='iconActive' />
                            <article className={classes.BTNSlideName}> ارشيف الطباعة</article>
                        </div>
                    </NavLink>
                    {/* <NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/delevryReports' >
                        <div id='BTNSIDEbar'>
                            <ReportIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>تقارير اليومية</article>
                        </div>
                    </NavLink> */}

                    < NavLink activeClassName='ActiveSliderBTN' to='/calsslog' >
                        <div id='BTNSIDEbar'>
                            <SupportAgent id='iconActive' />
                            <article className={classes.BTNSlideName}>سجل تأكيد الطلبات </article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/replacement' >
                        <div id='BTNSIDEbar'>
                            <Loop id='iconActive' />
                            <article className={classes.BTNSlideName}> الاستبدالات</article>
                        </div>
                    </NavLink>
                    <NavLink activeClassName='ActiveSliderBTN' to='/logs' >
                        <div id='BTNSIDEbar'>
                            <AlarmOn id='iconActive' />
                            <article className={classes.BTNSlideName}>غير متصل بيهم</article>
                        </div>
                    </NavLink>
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/logscan'>
                        <div id='BTNSIDEbar'>
                            <ManageHistory id='iconActive' />
                            <article className={classes.BTNSlideName}>سجل لوحة االتحكم</article>
                        </div>
                    </NavLink> */}
                    <NavLink activeClassName='ActiveSliderBTN' to='/fromordersWatting' >
                        <div id='BTNSIDEbar'>
                            <LocalPrintshopOutlined id='iconActive' />
                            <article className={classes.BTNSlideName}>قبل الطباعة</article>
                        </div>
                    </NavLink>

                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/cancelledOrders' >
                        <div id='BTNSIDEbar'>
                            <Close id='iconActive' />
                            <article className={classes.BTNSlideName}>الملغي </article>
                        </div>
                    </NavLink> */}

                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/RejectOrder' >
                        <div id='BTNSIDEbar'>
                            <RejectIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>الراجع </article>
                        </div>
                    </NavLink> */}

                    {/* 
                    <NavLink activeClassName='ActiveSliderBTN' to='/apadningOrders' >
                        <div id='BTNSIDEbar'>
                            <HourglassTopIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>الطلبات المعلقة</article>
                        </div>
                    </NavLink> */}
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/calledOrders' >
                        <div id='BTNSIDEbar'>
                            <CallIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>الطلبات المبلغه</article>
                        </div>
                    </NavLink> */}



                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/sheet' >
                        <div id='BTNSIDEbar'>
                            <LocalPrintshopIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>قوائم اليومية</article>
                        </div>
                    </NavLink> */}

                    <NavLink activeClassName='ActiveSliderBTN' to='/Search' >
                        <div id='BTNSIDEbar'>
                            <SearchIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>بحث</article>
                        </div>
                    </NavLink>


                    <NavLink activeClassName='ActiveSliderBTN' to='/rejctedList' >
                        <div id='BTNSIDEbar'>
                            <SearchIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>قوائم الراجع</article>
                        </div>
                    </NavLink>

                    <NavLink style={role == 0 ? { display: 'none' } : { display: 'flex' }} activeClassName='ActiveSliderBTN' to='/AdsReprots' >
                        <div id='BTNSIDEbar'>
                            <AccessTimeIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>تقارير الترويج</article>
                        </div>
                    </NavLink>
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/wattingselles' >
                        <div id='BTNSIDEbar'>
                            <AccessTimeIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>تقارير الانتظار</article>
                        </div>
                    </NavLink> */}
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/check' >
                        <div id='BTNSIDEbar'>
                            <AccessTimeIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>تقارير الغير مؤكد</article>
                        </div>
                    </NavLink> */}
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/miss' >
                        <div id='BTNSIDEbar'>
                            <AccessTimeIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>تقارير الايرد </article>
                        </div>
                    </NavLink> */}


                    {/* <NavLink  activeClassName='ActiveSliderBTN' to='/M3alk' >
                        <div id='BTNSIDEbar'>
                            <LocalShipping id='iconActive' />
                            <article className={classes.BTNSlideName}> تقارير المعلق </article>
                        </div>
                    </NavLink> */}
                    {/* <NavLink activeClassName='ActiveSliderBTN' to='/selles' >
                        <div id='BTNSIDEbar'>
                            <AssessmentIcon id='iconActive' />
                            <article className={classes.BTNSlideName}> تقارير المبيعات </article>
                        </div>
                    </NavLink> */}
                    {/* <NavLink style={role!==1? {display:'none'}:{display:'flex'} } activeClassName='ActiveSliderBTN' to='/doneselles' >
                        <div id='BTNSIDEbar'>
                            <AssignmentTurnedInIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>تقارير الناجح</article>
                        </div>
                    </NavLink> */}
                    {/* <NavLink  activeClassName='ActiveSliderBTN' to='/DoneTable' >
                        <div id='BTNSIDEbar'>
                            <AssignmentTurnedInIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>انجاح الطلبات</article>
                        </div>
                    </NavLink> */}

                    <NavLink activeClassName='ActiveSliderBTN' to='/SettingDash' >
                        <div id='BTNSIDEbar'>
                            <BarChartIcon id='iconActive' />
                            <article className={classes.BTNSlideName}>التقارير</article>
                        </div>
                    </NavLink>
                    <div id='BTNSIDEbar'
                        onClick={() => {
                            cookies.remove("token")
                            window.location.href = '/'
                        }}>
                        <MeetingRoomIcon />
                        <div className={classes.BTNSlideName}>تسجيل الخروج</div>
                    </div>


                </div>
            </div>
        </div>
    );

    if (cookies.get("token")) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/profile/`, { headers: header })
            .then(res => {
                billsCount = res.data.data.billsCount
                localStorage.setItem("bills", res.data.data.billsCount)
            })
            .catch(error => {
                window.location.href = "/";
            })
        return (
            <div className={classes.root}>

                <Toolbar id='NavContinerApp'>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <nav className={classes.drawer} aria-label="mailbox folders">

                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">

                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor='right'
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            <div >

                                {drawer}
                            </div>
                        </Drawer>
                    </Hidden>

                    <Hidden xsDown implementation="css">

                        <Drawer
                            anchor='right'
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            <div >
                                {drawer}
                            </div>
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {renderPage(props)}
                </main>
            </div>
        )


    }
    else {
        return (
            <Login />
        )
    }

}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */

    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};
const renderPage = (props) => {
    // if (props.match.path === '/Setting') {
    //     return (<Sitting />)
    // }
    if (props.match.path === '/Cate2') {
        return (<Cate2 />)
    }
    if (props.match.path === '/safe') {
        return (<Safe />)
    }
    if (props.match.path === '/uncheckedBill') {
        return (<UncheckedBill />)
    }



    else if (props.match.path === '/Order2Dash') {
        return (<Order2 />)
    }

    else if (props.match.path === '/doneselles') {
        return (<DoneReports />)
    }

    else if (props.match.path === '/billsList') {
        return (<BillsList />)
    }
    else if (props.match.path === '/suppliersList') {
        return (<SuppliersList />)
    }



    else if (props.match.path === '/wattingselles') {
        return (<Wattingselles />)
    }
    else if (props.match.path === '/wattingselles') {
        return (<Wattingselles />)
    }
    else if (props.match.path === '/printlist') {
        return (<Print_list />)
    }
    else if (props.match.path === '/miss') {
        return (<Miss />)
    }

    else if (props.match.path === '/SettingDash') {
        return (<Setting />)
    }
    else if (props.match.path === '/Expenses') {
        return (<Expenses />)
    }
    else if (props.match.path === '/EmployeeList') {
        return (<UsersList />)
    }
    else if (props.match.path === '/shippingcompaniesList') {
        return (<ShippingcompaniesList />)
    }
    else if (props.match.path === '/unavailable') {
        return (<Unavailable />)
    }

    


    else if (props.match.path === '/delevryReports') {
        return (<DelveryReports />)
    }
    else if (props.match.path === '/fromorders') {
        return (<FromOrders />)
    }
    else if (props.match.path === '/fromordersWatting') {
        return (<FromOrdersWatting />)
    }
    else if (props.match.path === '/selles') {
        return (<Sells />)
    }
    else if (props.match.path === '/apadningOrders') {
        return (<Apanding_order />)
    }
    else if (props.match.path === '/cancelledOrders') {
        return (<CancelledOrders />)
    }
    else if (props.match.path === '/logs') {
        return (<Logs />)
    }
    else if (props.match.path === '/logscan') {
        return (<LogsCan />)
    }


    else if (props.match.path === '/Search') {
        return (<Search />)
    }
    else if (props.match.path === '/DoneOrder') {
        return (<DoneOrder />)
    }
    else if (props.match.path === '/RejectOrder') {
        return (<RejectOrder />)
    }
    else if (props.match.path === '/calledOrders') {
        return (<CalledOrders />)
    }
    else if (props.match.path === '/DoneTable') {
        return (<DoneTable />)
    }
    else if (props.match.path === '/sheet') {
        return (<Sheet />)
    }
    else if (props.match.path === '/check') {
        return (<Check />)
    }
    else if (props.match.path === '/AdsReprots') {
        return (<AdsReprots />)
    }


    else if (props.match.path === '/print') {
        return (<Print />)
    }
    else if (props.match.path === '/calsslog') {
        return (<Calsslog />)
    }

    else if (props.match.path === '/m3alk') {
        return (<M3alk />)
    }
    else if (props.match.path === '/rejctedList') {
        return (<RejctedList />)
    }

    else if (props.match.path === '/replacement') {
        return (<Replacement />)
    }


}
export default ResponsiveDrawer;
