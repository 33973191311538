import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Button, Container, Form, Table, ButtonGroup } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import * as moment from "moment-timezone";

import 'react-toastify/dist/ReactToastify.css';
import { disable } from 'express/lib/application';
// minified version is also included
const cookies = new Cookies();
const role = Number(localStorage.getItem('role'))
const items = [
    {
        id: 0,
        name: 'Cobol'
    },
    {
        id: 1,
        name: 'JavaScript'
    },
    {
        id: 2,
        name: 'Basic'
    },
    {
        id: 3,
        name: 'PHP'
    },
    {
        id: 4,
        name: 'Java'
    }
]







class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
            name: '',
            sn: '',
            notes: '',
            battery: "",
            id: "",
            itemsCount: 1,
            suppliers: [],
            items: [],
            t_price: 0,
            sup_id: "",
            type: "",
            "paid_amount": '',
            "r_amount": '',
            "payed": '',
            "status": '',
            "shipping": '',
            "add_at": '',
            "supplierName": '',
            Getid: "",
            msg: ""

        }

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        this.setState({ Getid: Getid })
    }


    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/bill/get/${Getid}`, { headers: header })
            .then(res => {
                let arry = []
                let msg = ""
                if (res.data.bills.status == 0) {
                    msg = 'غير مدققة'
                } else if (res.data.bills.status == 1) {
                    msg = 'انتظار'
                } else if (res.data.bills.status == 2) {
                    msg = 'تم الادخال الى المخزن'
                }
                else if (res.data.bills.status == 3) {
                    msg = 'ملغية'
                }


                this.setState({
                    // suppliers: res.data.suppliers,
                    items: res.data.bills.item,
                    t_price: res.data.bills.t_amount,

                    "paid_amount": res.data.bills.paid_amount,
                    "r_amount": res.data.bills.r_amount,
                    "type": res.data.bills.type,
                    "payed": res.data.bills.payed,
                    "status": res.data.bills.status,
                    "shipping": res.data.bills.shipping,
                    "notes": res.data.bills.notes,
                    "add_at": res.data.bills.add_at,
                    "supplierName": res.data.bills.suppliers.name,
                    msg

                });
            })
            .catch(error => { console.log(error.response) })





    }

    edit_shipping_price(id, price) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/edit/${Getid}`, {
            "prcie": price,

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }
    get_list_price() {
        let price = 0
        for (let index = 0; index < this.state.itemsCount; index++) {
            let p = document.getElementById(`price${index + 1}`).value * document.getElementById(`count${index + 1}`).value
            price = price + p
        }
        this.setState({ t_price: price })

    }

    send() {
        let sup_id = this.state.sup_id;
        let listDate = document.getElementById("listDate").value;
        let notes = document.getElementById("notes").value;
        let shipping = document.getElementById("shipping").value;
        let t_price = this.state.t_price
        let type = this.state.type

        let items = [];

        for (let index = 0; index < this.state.itemsCount; index++) {

            let count = document.getElementById(`count${index + 1}`).value;
            let items_id = document.getElementById(`item${index + 1}`).value;
            let price = document.getElementById(`price${index + 1}`).value;

            if (!count || count == "" || count < 1) {
                toast.error('حصل خطأ تأكد من صحة المعلومات', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return -1;
            }
            if (!items_id || items_id == "") {
                toast.error('حصل خطأ تأكد من صحة المعلومات', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return -1;
            }
            if (!price || price == "" || price < 1) {
                toast.error('حصل خطأ تأكد من صحة المعلومات', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return -1;
            }
            let obj = {
                count,
                price,
                items_id
            }
            items.push(obj)
        }

        let check = document.getElementById('custom-switch').checked;
        let new_price = 0;
        if (check) {

            let p_price = document.getElementById('paidPrit').value;
            new_price = t_price - p_price;
        } else {
            new_price = t_price;
        }
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/new`, {
            "sup_id": sup_id,
            "listDate": listDate,
            'notes': notes,
            'shipping': shipping,
            "items": items,
            "check": check,
            'price': t_price,
            'p_price': new_price,
            'type': type
        })
            .then(response => {

                // window.location.href = "/Cate2";
                this.setState({ spinBtn: false })
            })
            .catch(error => {

            });
        console.log({
            "sup_id": sup_id, "listDate": listDate, 'notes': notes, 'shipping': shipping,
            "items": items, 'price': t_price, 'p_price': new_price
        });

    }
    chage_status(status) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/status/${Getid}`, {
            "status": status,

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }

    get_inv(id) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        let safeOut = document.getElementById('safeOut').checked;
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/in/${Getid}`, {
            "safeOut": safeOut,

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }
    render() {

        if (cookies.get("token")) {
            return (
                <div>
                    <ToastContainer />
                    <div id='navDashContiner'>
                        <Link to={`billsList`}><  KeyboardBackspace style={{ color: "#fff", fontSize: 30 }} /></Link>

                    </div>
                    <Container>
                        <Row></Row>
                        <br></br>
                        <hr></hr>
                        <Row dir="rtl">
                            <Col >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Label >اسم التاجر </Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.supplierName} disabled />


                                </Form.Group>
                            </Col>
                            <Col >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label> تاريخ القائمة </Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={moment(this.state.add_at).format('DD/MM/YYYY')} disabled />
                                </Form.Group>

                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>  سعر التوصيل </Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.shipping} disabled />
                                </Form.Group>

                            </Col>
                            <Col style={this.state.type == 1 ? {} : { display: 'none' }}   >
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <SideSheet
                                                isShown={state.isShown}
                                                position={Position.LEFT}
                                                onCloseComplete={() => setState({ isShown: false })}
                                            >
                                                <Container>

                                                    <br></br>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                                <Form.Label>  سعر التوصيل </Form.Label>
                                                                <Form.Control type="text " style={{ textAlign: 'center' }} id="editshp" />
                                                            </Form.Group>
                                                            <center>
                                                                <Button onClick={() => {
                                                                    let price = document.getElementById("editshp").value;
                                                                    this.edit_shipping_price(this.state.Getid, price)
                                                                }}>تعديل</Button>
                                                            </center>
                                                        </Col>

                                                    </Row>
                                                </Container>
                                            </SideSheet>
                                            <div id='BtnAddUser' style={this.state.status == 2 || this.state.status == 3 ? { display: 'none' } : { backgroundColor: '#ffc107', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}> سعر النقل</div>
                                        </React.Fragment>
                                    )}
                                </Component>
                            </Col>
                        </Row>
                        <Row dir="rtl">
                            <Col>

                                <Form.Label>   نوع النقل </Form.Label>
                                <Form.Control type="text " style={this.state.type == 0 ? { display: 'none' } : { textAlign: 'center' }} value={'خارجي'} disabled />
                                <Form.Control type="text " style={this.state.type == 1 ? { display: 'none' } : { textAlign: 'center' }} value={'داخلي'} disabled />



                            </Col>
                            <Col>
                                <Form.Label>    الحالة </Form.Label>
                                <Form.Control s type="text " value={this.state.msg} disabled />
                            </Col>
                            <Col>
                                <Form.Label htmlFor="inputPassword5">ملاحظات </Form.Label>
                                <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.notes} disabled />

                            </Col>
                        </Row>
                        <hr></hr>

                        <br></br>
                        <Row>
                            <Col>
                                <Table striped bordered hover >
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}> السعر الكلي للمنتج  </th>
                                            <th style={{ textAlign: 'center' }}>سعر القطعة  </th>
                                            <th style={{ textAlign: 'center' }}>العدد </th>
                                            <th colSpan={3} style={{ textAlign: 'center' }}>المنتج</th>
                                            <th style={{ textAlign: 'center' }}>id</th>
                                            <th style={{ textAlign: 'center' }}>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>


                                        {this.state.items.map((item, i) =>
                                            <tr>
                                                <td><input style={{ textAlign: 'center', height: "50px" }} type='number' id={`price${i}`} value={(item.price * item.count).toFixed(2)} disabled></input></td>
                                                <td><input style={{ textAlign: 'center', height: "50px" }} type='number' id={`price${i}`} value={item.price.toFixed(2)} disabled></input></td>
                                                <td><input style={{ textAlign: 'center', height: "50px" }} type='number' id={`count${i}`} value={item.count} disabled></input></td>
                                                <td colSpan={3} style={{ width: "40%", zIndex: 9 }}>
                                                    <input hidden width={0} id={`item${i}`} ></input>
                                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={item.name} disabled />

                                                </td>
                                                <td>{item.id}</td>
                                                <td>{i + 1}</td>
                                            </tr>
                                        )}


                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0da530' }}>{this.state.paid_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0da530' }}> المبلغ المدفوع </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#e51919' }}>{this.state.shipping}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#e51919' }}> مصاريف اخرى </td>
                                        </tr>

                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#ffc107' }}>{this.state.r_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#ffc107' }}> المبلغ المتبقي </td>
                                        </tr>

                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.t_price + this.state.shipping}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}>المجموع الكلي </td>
                                        </tr>

                                    </tbody>
                                </Table>

                            </Col>

                        </Row>

                        <Form.Check
                            style={this.state.status == 1 ? { margin: 10 } : { display: 'none' }}
                            type={"checkbox"}
                            label={`اخراج مصاريف الشحن من القصة`}
                            id={`safeOut`}
                        />

                        <hr></hr>
                        <Button style={this.state.status == 1 ? { margin: 10 } : { display: 'none' }} variant="primary" onClick={() => {
                            this.get_inv(this.state.Getid)
                        }}>ادخال الى المخزن</Button>
                        <Button style={this.state.status == 0 && role == -1 ? { margin: 10 } : { display: 'none' }} variant="success" onClick={() => {
                            this.chage_status(1)
                        }}>قبول </Button>
                        <Button style={this.state.status == 0 && role == -1 ? { margin: 10 } : { display: 'none' }} variant="danger" onClick={() => {
                            this.chage_status(3)
                        }}>رفض </Button>
                    </Container>
                </div >
            )
        }
        else {
            return (
                <Login />
            )
        }

    }

}


export default Category;