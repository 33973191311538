import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import Component from "@reactions/component";
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Pane, Dialog, Spinner, SideSheet, Position, SelectField } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col, Card, ListGroup, Button, Container, Form, Table, ButtonGroup, Tabs, Tab } from 'react-bootstrap';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Logo from '../../assets/img/printlogo.jpg';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import * as moment from "moment-timezone";
import Info from '@material-ui/icons/Info';

import 'react-toastify/dist/ReactToastify.css';
import { disable } from 'express/lib/application';
// minified version is also included
const cookies = new Cookies();



let allRowsSelectedOrderList = [];
let allRowsSelectedRejectedList = [];
let allRowsSelectedPayment = [];
const columns2 = [


    { field: "createdAt", name: " تاريخ الدفعة", options: { width: 200, filter: false, sort: false, } },
    { field: "notes", name: "ملاحظات", options: { filter: false, sort: true, } },
    { field: "amount", name: "مبلغ الكلي", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم ", options: { width: 200, filter: false, sort: false, } },

];


const columnsM = [

    { field: "more", name: "المزيد", options: { width: 200, filter: false, sort: false, } },

    { field: "createdAt", name: "التاريخ", options: { width: 200, filter: false, sort: false, } },

    { field: "rejected_amount", name: "مبلع الراجع", options: { width: 200, filter: false, sort: false, } },
    { field: "orders_amount", name: "مبلغ البريد ", options: { filter: false, sort: true, } },
    { field: "payment_amount", name: "مبلغ المستلم", options: { filter: false, sort: true, } },
    { field: "remaining_amount", name: "المتبقي ", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم ", options: { width: 200, filter: false, sort: false, } },

];
const optionsM = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 10,

    filter: false,

    download: false,

    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}


const columns1 = [


    { field: "createdAt", name: " تاريخ القائمة", options: { width: 200, filter: false, sort: false, } },
    { field: "order_count", name: " عدد الطلبات", options: { filter: false, sort: true, } },
    { field: "price", name: "مبلغ الكلي", options: { filter: false, sort: true, } },
    { field: "id", name: "رقم القائمة", options: { width: 200, filter: false, sort: false, } },

];
const options1 = {
    selectableRows: true,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 10,

    filter: false,

    download: false,
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
        console.log(allRowsSelected);
        allRowsSelectedOrderList = allRowsSelected
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const options2 = {
    selectableRows: true,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 10,

    filter: false,

    download: false,
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
        console.log(allRowsSelected);
        allRowsSelectedRejectedList = allRowsSelected
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const options3 = {
    selectableRows: true,
    responsive: 'scroll',
    rowCursorHand: false,
    print: true,
    sort: false,
    rowsPerPage: 10,

    filter: false,

    download: false,
    onRowsSelect: (currentRowsSelected, allRowsSelected) => {
        console.log(allRowsSelected);
        allRowsSelectedPayment = allRowsSelected
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}


class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


            spin: false,
            t_price: 0,
            "paid_amount": '',
            "r_amount": '',
            bills_count: 0,
            "add_at": '',
            "supplierName": '',
            Getid: "",
            amount: 0,
            bills: [],
            payment: [],
            ordert_list: [],
            rejected_list: [],
            payments: [],
            name: '',
            remaining_amount: 0,
            mtabka: []


        }

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        this.setState({ Getid: Getid })
    }


    componentDidMount() {

        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/Shipping/get/${Getid}`, { headers: header })
            .then(res => {
                let arry = []


                let order_list = [];
                let rejected_list = [];
                let payments = [];
                let remaining_amount = 0
                if (res.data.mtabka) {
                    remaining_amount = res.data.mtabka.remaining_amount
                }
                for (let index = 0; index < res.data.order_list.length; index++) {
                    let obj = {
                        id: res.data.order_list[index].id,
                        order_count: res.data.order_list[index].order_count,

                        price: res.data.order_list[index].price,

                        createdAt: moment(res.data.order_list[index].createdAt).format('DD/MM/YYYY'),

                    }
                    order_list.push(obj)
                }

                for (let index = 0; index < res.data.rejected_list.length; index++) {
                    let obj = {
                        id: res.data.rejected_list[index].id,
                        order_count: res.data.rejected_list[index].order_count,

                        price: res.data.rejected_list[index].price,

                        createdAt: moment(res.data.rejected_list[index].createdAt).format('DD/MM/YYYY'),

                    }
                    rejected_list.push(obj)
                }
                for (let index = 0; index < res.data.payment.length; index++) {
                    let obj = {
                        id: res.data.payment[index].id,
                        amount: res.data.payment[index].amount,
                        notes: res.data.payment[index].notes,

                        createdAt: moment(res.data.payment[index].add_at).format('DD/MM/YYYY'),

                    }
                    payments.push(obj)
                }
                let name = res.data.company.name


                this.setState({ order_list, rejected_list, payments, name, remaining_amount: remaining_amount })

            })
            .catch(error => { console.log(error.response) })


        axios.get(host + `dashbord/Shipping/mtabka/get/${Getid}`, { headers: header })
            .then(res => {
                let arry = []
                let mtabka = []

                for (let index = 0; index < res.data.mtabkat.length; index++) {
                    let obj = {

                        id: res.data.mtabkat[index].id,
                        orders_amount: res.data.mtabkat[index].orders_amount,
                        payment_amount: res.data.mtabkat[index].payment_amount,

                        rejected_amount: res.data.mtabkat[index].rejected_amount,

                        remaining_amount: res.data.mtabkat[index].remaining_amount,

                        createdAt: moment(res.data.mtabkat[index].createdAt).format('DD/MM/YYYY'),
                        more: <Link to={`mtabka?id=${res.data.mtabkat[index].id}`}><Info /></Link>
                    }
                    mtabka.push(obj)

                }


                this.setState({ mtabka })

            })
            .catch(error => { console.log(error.response) })




    }

    edit_shipping_price(id, price) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/edit/${id}`, {
            "prcie": price,

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }
    get_list_price() {
        let price = 0
        for (let index = 0; index < this.state.itemsCount; index++) {
            let p = document.getElementById(`price${index + 1}`).value * document.getElementById(`count${index + 1}`).value
            price = price + p
        }
        this.setState({ t_price: price })

    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })



    add_payment() {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        let amount = document.getElementById('inputpayment').value
        let add_at = document.getElementById('add_at').value
        let notes = document.getElementById('inputpaymentNotes').value

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/Shipping/payment/new`, {
            "amount": amount,
            id: Getid,
            add_at, notes

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }

    get_inv(id) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/bill/shpinng/in/${Getid}`, {


        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });
    }


    mtabka(printList, print_price, r_List, r_price, p_List, p_price, remaining_now, remaining_amount, p_amount, r_amoun) {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        document.getElementById('mtabkaBtn').style.display = "none"
        document.getElementById('spinnerM').style.display = "flex"


        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/Shipping/mtabka/new`, {

            id: Getid,
            printList, print_price, r_List, r_price, p_List, p_price, remaining_now, remaining_amount, p_amount, r_amoun

        })
            .then(response => {
                window.location.href = `/mtabka?id=${response.data.id}`;


            })
            .catch(error => {
                document.getElementById('mtabkaBtn').style.display = "flex"
                document.getElementById('spinnerM').style.display = "none"
                window.alert('حصل خطأ تأكد من صحة المعلومات')
            });
    }
    render() {

        if (cookies.get("token")) {
            return (
                <div >
                    <ToastContainer />
                    <div id='navDashContiner'>
                        <Link to={`shippingcompaniesList`}><  KeyboardBackspace style={{ color: "#fff", fontSize: 30 }} /></Link>

                    </div>

                    <br></br>
                    <hr></hr>
                    <Container >
                        <Row dir="rtl">
                            <Col >
                                <Form.Group dir="rtl" className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Label style={{ textAlign: 'right' }}>اسم الشركة</Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.name} disabled />

                                </Form.Group>

                            </Col>
                            <Col >
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                    <Form.Label >المتبقى بذمة الشركة</Form.Label>
                                    <Form.Control type="text " style={{ textAlign: 'center' }} value={this.state.remaining_amount} disabled />

                                </Form.Group>

                            </Col>
                            <Col >
                                <Component initialState={{ isShown: false }}>
                                    {({ state, setState }) => (
                                        <React.Fragment>
                                            <SideSheet
                                                isShown={state.isShown}
                                                position={Position.LEFT}
                                                onCloseComplete={() => setState({ isShown: false })}
                                            >
                                                <div margin={40}>
                                                    <div id='titleAddUser' style={{ backgroundColor: '#000' }}> اضافة دفعة </div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label> المبلغ </label>
                                                            <input autoComplete='off' type='number' placeholder='المبلغ ' id='inputpayment'
                                                            />
                                                        </div>


                                                    </div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label> ملاحظات </label>
                                                            <input autoComplete='off' type='text' placeholder='ملاحظات ' id='inputpaymentNotes'
                                                            />
                                                        </div>


                                                    </div>
                                                    <div id='inputAdd2Continer' >
                                                        <div id={'ContinerInPut'} >
                                                            <label> تاريخ الدفعة </label>
                                                            <input autoComplete='off' type='date' placeholder='المبلغ ' id='add_at'
                                                            />
                                                        </div>


                                                    </div>

                                                    <div id='btnAddContiner'>
                                                        <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                            this.add_payment()
                                                            setState({ isShown: false })
                                                        }}>اضافة</div>
                                                    </div>

                                                </div>
                                            </SideSheet>
                                            <Button onClick={() => setState({ isShown: true })}>اضافة دفعة</Button>
                                        </React.Fragment>
                                    )}
                                </Component>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >


                                </Form.Group>

                            </Col>
                        </Row>

                    </Container>

                    <hr></hr>

                    <br></br>


                    <Container>
                        <Row>
                            <Col>
                                <Table striped bordered hover >

                                    <tbody>

                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{this.state.t_price + this.state.amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}>المجموع الكلي  </td>
                                        </tr>


                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#0da530' }}>{this.state.paid_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#0da530' }}> المبلغ المدفوع </td>
                                        </tr>
                                        <tr>

                                            <td colSpan={3} style={{ textAlign: 'center', color: '#dc3545' }}>{this.state.r_amount}</td>
                                            <td colSpan={2} style={{ textAlign: 'right', color: '#dc3545' }}> المبلغ المتبقي </td>
                                        </tr>


                                    </tbody>
                                </Table>

                            </Col>

                        </Row>
                    </Container>


                    <Tabs
                        dir="rtl"
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="مطابقة جديدة">

                            <Component initialState={{ isShown: false, printList: [], r_List: [], p_List: [], print_price: 0, r_price: 0, p_price: 0, remaining_amount: 0, remaining_now: 0, p_amount: 0, r_amount: 0 }}>
                                {({ state, setState }) => (
                                    <React.Fragment>
                                        <SideSheet
                                            isShown={state.isShown}
                                            position={Position.LEFT}
                                            onCloseComplete={() => setState({ isShown: false })}
                                        >
                                            <div margin={40}>
                                                <div id='titleAddUser' style={{ backgroundColor: '#000' }}> اضافة دفعة </div>

                                                <div>


                                                    <br></br>
                                                    <hr></hr>
                                                    <center >    <h4 >  المتبقي على شركة {this.state.name} </h4></center>
                                                    <hr></hr>
                                                    <center>
                                                        <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>

                                                            <tbody>




                                                                <tr>
                                                                    <td>المبلغ</td>
                                                                    <td colSpan={2}>{state.remaining_amount}  </td>

                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </center>

                                                </div>
                                                <div>


                                                    <br></br>
                                                    <hr></hr>
                                                    <center>    <h4 > قوائم المبيعات </h4></center>
                                                    <hr></hr>
                                                    <center>
                                                        <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>
                                                            <thead>
                                                                <tr>
                                                                    <th>رقم القائمة</th>
                                                                    <th>المبلغ</th>
                                                                    <th>تاريخ القائمة</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {state.printList.map((order, i) =>
                                                                    <tr>
                                                                        <td>{order.id}</td>
                                                                        <td>{order.price}</td>
                                                                        <td>{order.date}</td>

                                                                    </tr>
                                                                )}


                                                                <tr>
                                                                    <td>المجموع</td>
                                                                    <td colSpan={2}>{state.print_price}  </td>

                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </center>

                                                </div>

                                                <div>


                                                    <br></br>
                                                    <hr></hr>
                                                    <center>    <h4 > قوائم الراجع </h4></center>
                                                    <hr></hr>
                                                    <center>
                                                        <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>
                                                            <thead>
                                                                <tr>
                                                                    <th>رقم القائمة</th>
                                                                    <th>المبلغ</th>
                                                                    <th>تاريخ القائمة</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {state.r_List.map((order, i) =>
                                                                    <tr>
                                                                        <td>{order.id}</td>
                                                                        <td>{order.price}</td>
                                                                        <td>{order.date}</td>

                                                                    </tr>
                                                                )}


                                                                <tr>
                                                                    <td>المجموع</td>
                                                                    <td colSpan={2}>{state.r_price}  </td>

                                                                </tr>
                                                            </tbody>
                                                        </Table>
                                                    </center>

                                                </div>



                                                <div>


                                                    <br></br>
                                                    <hr></hr>
                                                    <center>    <h4 >  المبالغ المستلمة </h4></center>
                                                    <hr></hr>
                                                    <center>
                                                        <Table striped bordered hover style={{ width: '90%' }} size="sm" dir='rtl'>
                                                            <thead>
                                                                <tr>
                                                                    <th>رقم القائمة</th>
                                                                    <th>المبلغ</th>
                                                                    <th>ملاحظات</th>
                                                                    <th>تاريخ القائمة</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {state.p_List.map((order, i) =>
                                                                    <tr>
                                                                        <td>{order.id}</td>
                                                                        <td>{order.price}</td>
                                                                        <td>{order.notes}</td>
                                                                        <td>{order.date}</td>

                                                                    </tr>
                                                                )}


                                                                <tr>
                                                                    <td>المجموع</td>
                                                                    <td colSpan={2}>{state.p_price}  </td>

                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                    </center>

                                                </div>


                                                <Table striped bordered hover >

                                                    <tbody>

                                                        <tr>

                                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{state.p_amount}</td>
                                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> المتبقى سابقآ + بريد اليوم  </td>
                                                        </tr>

                                                        <tr>

                                                            <td colSpan={3} style={{ textAlign: 'center', color: '#007bff' }}>{state.r_amount}</td>
                                                            <td colSpan={2} style={{ textAlign: 'right', color: '#007bff' }}> الراجع +النقد  </td>
                                                        </tr>

                                                        <tr>

                                                            <td colSpan={3} style={{ textAlign: 'center', color: '#dc3545' }}>{state.remaining_now}</td>
                                                            <td colSpan={2} style={{ textAlign: 'right', color: '#dc3545' }}>  المتبقي لغاية اليوم  </td>
                                                        </tr>



                                                    </tbody>
                                                </Table>


                                                <center><Button id={"mtabkaBtn"} onClick={() => {
                                                    this.mtabka(state.printList, state.print_price, state.r_List, state.r_price, state.p_List, state.p_price, state.remaining_now, state.remaining_amount, state.p_amount, state.r_amount)

                                                }}>مطابقة</Button></center>

                                                <center>
                                                    <Spinner id="spinnerM" style={{ display: "none" }} animation="border" variant="primary" />
                                                </center>



                                            </div>
                                        </SideSheet>
                                        <Button onClick={() => {

                                            let orders = this.state.order_list
                                            let rejected_list = this.state.rejected_list
                                            let payment = this.state.payments


                                            let printList = []
                                            let print_price = 0

                                            let r_List = []
                                            let r_price = 0

                                            let p_List = []
                                            let p_price = 0
                                            let remaining_amount = this.state.remaining_amount


                                            for (let index = 0; index < allRowsSelectedOrderList.length; index++) {
                                                let dataIndex = allRowsSelectedOrderList[index].dataIndex

                                                let obj = {
                                                    id: orders[dataIndex].id,
                                                    price: orders[dataIndex].price,
                                                    date: orders[dataIndex].createdAt,

                                                }
                                                printList.push(obj)
                                                print_price = print_price + orders[dataIndex].price

                                            }




                                            for (let index = 0; index < allRowsSelectedRejectedList.length; index++) {
                                                let dataIndex = allRowsSelectedRejectedList[index].dataIndex

                                                let obj = {
                                                    id: rejected_list[dataIndex].id,
                                                    price: rejected_list[dataIndex].price,
                                                    date: rejected_list[dataIndex].createdAt,

                                                }
                                                r_List.push(obj)
                                                r_price = r_price + rejected_list[dataIndex].price

                                            }



                                            for (let index = 0; index < allRowsSelectedPayment.length; index++) {
                                                let dataIndex = allRowsSelectedPayment[index].dataIndex

                                                let obj = {
                                                    id: payment[dataIndex].id,
                                                    price: payment[dataIndex].amount,
                                                    notes: payment[dataIndex].notes,
                                                    date: payment[dataIndex].createdAt,

                                                }
                                                p_List.push(obj)
                                                p_price = p_price + payment[dataIndex].amount

                                            }





                                            let p_amount = print_price + remaining_amount
                                            let r_amount = r_price + p_price
                                            let remaining_now = p_amount - r_amount







                                            setState({ isShown: true, printList, print_price, r_List, r_price, p_List, p_price, remaining_now, remaining_amount, p_amount, r_amount })
                                            //  this.mtabka_fillter()

                                        }}>مطابقة</Button>
                                    </React.Fragment>
                                )}
                            </Component>






                            <Row>
                                <Col md={{ offset: 1 }}>


                                    <MuiThemeProvider

                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable title="قوائم المبيعات" data={this.state.order_list} columns={columns1} options={options1} />
                                    </MuiThemeProvider>


                                </Col>
                                <Col >
                                    <MuiThemeProvider

                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable title="قوائم الراجع" data={this.state.rejected_list} columns={columns1} options={options2} />
                                    </MuiThemeProvider>
                                </Col>
                                <Col >
                                    <MuiThemeProvider

                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable title="الدفعات" data={this.state.payments} columns={columns2} options={options3} />
                                    </MuiThemeProvider>
                                </Col>

                                <Col md={1}></Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="profile" title="مطابقات السابقة">


                            <Row>
                                <Col md={{ offset: 1 }}>


                                    <MuiThemeProvider

                                        theme={this.getMuiTheme()}>
                                        <MaterialDatatable title="المتطابقات السابقة" data={this.state.mtabka} columns={columnsM} options={optionsM} />
                                    </MuiThemeProvider>


                                </Col>

                                <Col md={1}></Col>
                            </Row>
                        </Tab>

                    </Tabs>






                </div >
            )
        }
        else {
            return (
                <Login />
            )
        }

    }

}


export default Category;