import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Button } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/AttachFileOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'

// import { JsonToExcel } from "react-json-to-excel";


const columns = [


    { field: "status", name: "الحالة", options: { filter: true, sort: true, } },
    { field: "phone", name: "  رقم الزبون", options: { filter: true, sort: true, } },

    { field: "safee_prcie", name: " المبلغ الصافي", options: { filter: true, sort: true, } },

    { field: "s_prcie", name: "المبلغ التوصيل", options: { filter: true, sort: true, } },

    { field: "prcie", name: "المبلغ الكلي", options: { filter: true, sort: true, } },

    { field: "gev", name: "المحافظة", options: { filter: true, sort: true, } },



    { field: "date", name: "التاريخ", options: { filter: true, sort: true, } },
    { field: "id", name: "رقم الوصل", options: { width: 150, filter: true, sort: false, } },

    //   { field: "i", name: "#", options: { filter: true, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: false,
    rowsPerPage: 13020,
    search: false,
    filter: false,
    viewColumns: false,
    download: false,
    pagination: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let id = 0
class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            id: '',

        }
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')
        this.setState({ id: Getid })
        this.Orders(Getid)
        id = Getid
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontSize: '25px',
                    fontWeight: 'bold',
                    border: '2px solid'

                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '25px',
                    border: '2px solid'
                }
            },

        }
    })

    Orders() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/c`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.orderss
                console.log(items);
                let ordes2 = []
                for (let i = 0; i < items.length; i++) {
                    let price = 0;
                    let s_price = 0;
                    if (items[i].id == 11873) {
                        console.log(items[i].id == 11873);
                    }
                    if (items[i].government == 'بغداد') {
                        price = items[i].price - 5
                        s_price = 5
                    } else {
                        price = items[i].price - 6
                        s_price = 6
                    }

                    let obj = {
                        i: i + 1,

                        n: "   ",
                        status: "   ",
                        phone: items[i].phone,
                        s_prcie: s_price,
                        safee_prcie: price,
                        prcie: items[i].price,
                        gev: items[i].government,


                        id: items[i].id,
                        name: items[i].page_name,
                        date: moment(items[i].createdAt).format('DD/MM/YYYY'),


                    };
                    //    let price = items[i].price - 5
                    let obj2 = {
                        "تاريخ الضمني": "",
                        'الحالة': "   ",
                        ' رقم الزبون': items[i].phone,
                        'المبلغ الصافي ': items[i].price - 5,
                        ' المبلغ التوصيل': 5,
                        'المبلغ الكلي': items[i].price,

                        'المحافظة': items[i].government,


                        'رقم الوصل': items[i].id,
                        ' اسم البيج': items[i].page_name,
                        'التاريخ': moment(items[i].createdAt).format('DD/MM/YYYY'),











                    };
                    arr.push(obj);
                    ordes2.push(obj2)
                }


                this.setState({
                    orders_count: items.length,
                    orders: arr,
                    spin: false,


                    ordes2
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    download() {
        const data = this.state.ordes2
        const fileName = `${moment(new Date()).format('YYYY-MM-DD')}/${id}`
        const exportType = exportFromJSON.types.xls

        exportFromJSON({ data, fileName, exportType })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div id='navDashContiner'>

                                    {/* <button
                                        onClick={() => {
                                            this.download()
                                        }}
                                    ></button> */}
                                </div>
                                <div id='ContinerReportDatePages'>
                                    <Row id='RowReportDate'>
                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <span>من : &nbsp;</span>
                                            <DatePicker id='date1'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <span>الى : &nbsp;</span>
                                            <DatePicker id='date2'
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.to}
                                                onChange={this.handleChangeTo}
                                            />
                                        </Col>


                                        <Col id='ColReportDate' sm={12} md={3}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.Orders()
                                            }}>بحث</div>
                                        </Col>

                                    </Row>
                                </div>

                                <br />


                                <div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <div style={{ textAlign: 'left', marginLeft: '15px' }}>
                                            <h5> عدد الطلبات :{this.state.orders.length} </h5>
                                            <br />
                                        </div>
                                        <div style={{ textAlign: 'right', marginLeft: '20px' }}>
                                            <Button marginRight={16} appearance="primary" intent="success"
                                                iconBefore={SearchIcon}
                                                onClick={() => {
                                                    this.download()
                                                }}
                                                height={45}
                                            >
                                                تحميل
                                            </Button>


                                        </div>
                                    </div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;