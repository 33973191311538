import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { SideSheet, Position, Dialog } from 'evergreen-ui';
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

import Login from '../common/login';
import { Button, Row, Col, Card, ListGroup, Container, Form, Spinner } from 'react-bootstrap';
import { TimePicker } from 'react-ios-time-picker';

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            users: [],
            spin: false,
        }
    }
    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/users/all`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.users);
                let users = res.data.data.users
                for (let index = 0; index < users.length; index++) {
                    if (users[index].start > 12) {
                        users[index].start = users[index].start - 12 + " مساءآ "
                    } else {
                        users[index].start = users[index].start + " صباحآ "
                    }
                    if (users[index].end > 12) {
                        users[index].end = users[index].end - 12 + " مساءآ "
                    } else {
                        users[index].end = users[index].end + " صباحآ "
                    }
                    arr.push(users[index])
                }
                this.setState({
                    users: arr, spin: false
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <a href={`/Employee`}><KeyboardBackspace style={{ color: '#fff', cursor: 'pointer', fontSize: 35 }} /></a>

                                </div>
                                <br></br>

                                <Container>

                                    <Row>
                                        {this.state.users.map((item, index) => (
                                            <Col>
                                                <Card dir='rtl' style={{ textAlign: "right", width: '18rem', margin: "15px" }}>

                                                    <Card.Body>
                                                        <Card.Title>{item.name} <Spinner style={item.isOn == 0 ? { display: 'none' } : { display: 'inline-block' }} animation="grow" variant="success" /> <Spinner style={item.isOn == 1 ? { display: 'none' } : { display: 'inline-block' }} animation="grow" variant="danger" /></Card.Title>

                                                    </Card.Body>
                                                    <ListGroup className="list-group-flush">
                                                        <ListGroup.Item>   وقت البدا الدور ساعة : {item.start}  </ListGroup.Item>
                                                        <ListGroup.Item>   وقت انتهاء الدور ساعة : {item.end}  </ListGroup.Item>

                                                    </ListGroup>
                                                    <Card.Body>
                                                        <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', }}>
                                                            {({ state, setState }) => (
                                                                <React.Fragment>
                                                                    <Dialog
                                                                        title={`تعدل جدول ${item.name}`}

                                                                        isShown={state.isShown}
                                                                        hasFooter={false}
                                                                        onCloseComplete={() => setState({ isShown: false })}
                                                                    >
                                                                        <Row>
                                                                            <div style={{ width: "100%" }}>
                                                                                <div id='titleAddUser' style={{ backgroundColor: '#000' }}>تعديل التوقيت</div>
                                                                                <div id='inputAdd2Continer' >
                                                                                    <div style={{ textAlign: 'center' }}>  وقت البدأ</div>
                                                                                    <div style={{ textAlign: 'center', margin: "10px" }} >  <TimePicker id="start" value={'10:00'} /></div>
                                                                                </div>
                                                                                <div id='inputAdd2Continer' >
                                                                                    <div style={{ textAlign: 'center' }}>  وقت الانتهاء</div>
                                                                                    <div style={{ textAlign: 'center', margin: "10px" }} >  <TimePicker id="end" value={'12:00'} /></div>
                                                                                </div>
                                                                                <div id='inputAdd2Continer' >
                                                                                    <div style={{ textAlign: 'center' }}> هل فعال لاتسلام الطلبات؟</div>
                                                                                    <div style={{ textAlign: 'center', margin: "10px" }} >
                                                                                        <Form.Check // prettier-ignore
                                                                                            dir='rtl'
                                                                                            width={100}
                                                                                            defaultChecked={item.isOn}
                                                                                            type="switch"
                                                                                            id="isOn"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div id='btnAddContiner'>
                                                                                    <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                                        let start = document.getElementById('start').value;
                                                                                        let end = document.getElementById('end').value;
                                                                                        let isOn = document.getElementById('isOn').checked;

                                                                                        axios.post(host + `users/Schedule/edit/${item.id}`,
                                                                                            qs.stringify({ start, end, isOn }), {
                                                                                            headers: {
                                                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                                            }
                                                                                        })
                                                                                            .then(response => {
                                                                                                toast('تمت التعديل التوقيت بنجاح', {
                                                                                                    position: "bottom-center",
                                                                                                    autoClose: 5000,
                                                                                                    hideProgressBar: false,
                                                                                                    closeOnClick: true,
                                                                                                    pauseOnHover: true,
                                                                                                    draggable: true
                                                                                                });
                                                                                                this.componentDidMount()
                                                                                            })
                                                                                            .catch(error => {
                                                                                                console.log(error.response.data.message)
                                                                                                this.setState({ spinerBtn: false })
                                                                                            })
                                                                                        setState({ isShown: false })
                                                                                    }}>تعديل</div>
                                                                                </div>

                                                                            </div>
                                                                        </Row>

                                                                    </Dialog>

                                                                    <Button onClick={() => setState({ isShown: true })} variant="primary">تعديل التوقيت</Button>
                                                                </React.Fragment>
                                                            )}
                                                        </Component>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                        ))}
                                    </Row>
                                </Container>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;