import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
// import qs from 'qs';
import PersonIcon from '@material-ui/icons/Person';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import Component from "@reactions/component";
import { Link } from 'react-router-dom';
import { Pane, Dialog, Table, Spinner, Icon } from 'evergreen-ui';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Tabs, Tab } from 'react-bootstrap';
import Paper from '@material-ui/core/Paper';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as moment from "moment-timezone";
import Login from '../web/login';
const columns = [
    { field: "items", name: "المواد", options: { width: 100, filter: true, sort: false, } },
    { field: "newPrice", name: "السعر بعد الخصم", options: { filter: true, sort: false, } },
    { field: "discount", name: "نسبه الخصم", options: { filter: true, sort: false, } },
    { field: "price", name: "السعر", options: { filter: true, sort: false, } },
    { field: "date", name: "تاريخ الطلب", options: { filter: true, sort: true, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            nameCate: '',
            Category: [],
            Category1:[],
            spin: false,
            spinerMod: false,
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {
        var name = localStorage.getItem('nameCustomer')
        var phone = localStorage.getItem('phoneCustomer')
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `users/customer/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                // console.log(res.data.data.orders);
                for (let i = 0; i < res.data.data.orders.length; i++) {
                    let obj = {
                        date: moment(res.data.data.orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT'),
                        price: res.data.data.orders[i].price,
                        discount: res.data.data.orders[i].discount + '%',
                        newPrice: res.data.data.orders[i].new_price,
                        items: 
                        <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                            {this.state.spinerMod ? (<Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                                                <Spinner />
                                            </Pane>) : (

                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell >اسم المادة</Table.TextHeaderCell>
                                                            <Table.TextHeaderCell>العدد</Table.TextHeaderCell>
                                                            <Table.TextHeaderCell>صوره</Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={240}>
                                                            {res.data.data.orders[i].orderItems.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell>{item.item.name}</Table.TextCell>
                                                                    <Table.TextCell>{item.count}</Table.TextCell>
                                                                    <Table.TextCell>
                                                                        <img src={host + 'files/' + item.item.photo} alt='img' style={{ height: 40, width: 40, borderRadius: 5 }} />
                                                                    </Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                )}
                                        </div>

                                    </Dialog>
                                    <VisibilityIcon style={{ cursor: 'pointer', color: '#77da5e' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    Category: arr, spin: true, name, phone
                });
            })
            .catch(error => { console.log(error.response) })

            axios.get(host + `users/customer/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.component_orders);
                for (let i = 0; i < res.data.data.component_orders.length; i++) {
                    let obj = {
                        date: moment(res.data.data.component_orders[i].createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT'),
                        price: res.data.data.component_orders[i].price,
                        discount: res.data.data.component_orders[i].discount + '%',
                        newPrice: res.data.data.component_orders[i].new_price,
                        items: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasFooter={false}
                                        hasHeader={false}
                                    >
                                        <div style={{ direction: 'rtl', textAlign: 'right' }}>
                                            {this.state.spinerMod ? (<Pane display="flex" alignItems="center" justifyContent="center" height={400}>
                                                <Spinner />
                                            </Pane>) : (

                                                    <Table>
                                                        <Table.Head>
                                                            <Table.TextHeaderCell >اسم المادة</Table.TextHeaderCell>
                                                            <Table.TextHeaderCell>العدد</Table.TextHeaderCell>
                                                            <Table.TextHeaderCell>صوره</Table.TextHeaderCell>
                                                        </Table.Head>
                                                        <Table.Body height={240}>
                                                            {res.data.data.component_orders[i].c_orderItems.map((item, i) =>
                                                                <Table.Row key={i} >
                                                                    <Table.TextCell>{item.components.name}</Table.TextCell>
                                                                    <Table.TextCell>{item.count}</Table.TextCell>
                                                                    <Table.TextCell>
                                                                        <img src={host + 'files/' + item.components.photo} alt='img' style={{ height: 40, width: 40, borderRadius: 5 }} />
                                                                    </Table.TextCell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                )}
                                        </div>

                                    </Dialog>
                                    <VisibilityIcon style={{ cursor: 'pointer', color: '#77da5e' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    Category1: arr, spin: true, name, phone
                });
            })
            .catch(error => { console.log(error.response) })
    }

    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Link to='/Customers'>
                                            <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                style={{ cursor: 'pointer' }} />
                                        </Link>
                                        <h6>{localStorage.getItem('cusName')}</h6>
                                    </div>

                                    <div id='headerCustomerOrderUserContiner'>
                                        <div>
                                            <PersonIcon /> {this.state.name}
                                        </div>
                                        <div>
                                            <PhoneInTalkIcon /> {this.state.phone}
                                        </div>
                                    </div>
                                    <div  style={{ direction:'rtl' }}>
                                    <Paper className={'classesroot'} >
                                        <Tabs defaultActiveKey="serivices" id="uncontrolled-tab-example" style={{backgroundColor: '#fafafa', width: 300, marginTop: 15 }}>
                                            <Tab eventKey='serivices' title="الخدمات" tabClassName='serivices'>
                                                <div className='DataTableContiner'>
                                                    <MuiThemeProvider
                                                        theme={this.getMuiTheme()}>
                                                        <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                                    </MuiThemeProvider>
                                                </div>
                                            </Tab>
                                            <Tab eventKey='items' title="المنتجات" tabClassName='items'>
                                                <div className='DataTableContiner'>
                                                    <MuiThemeProvider
                                                        theme={this.getMuiTheme()}>
                                                        <MaterialDatatable data={this.state.Category1} columns={columns} options={options} />
                                                    </MuiThemeProvider>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Paper>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;