import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog, Paragraph, Card, Heading } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import Info from '@material-ui/icons/Info';
import ReplayIcon from '@material-ui/icons/Replay';
import FileUpload from '@material-ui/icons/SyncRounded';

import SyncDisabledRounded from '@material-ui/icons/SyncDisabledRounded';

import CategoryIcon from '@material-ui/icons/Category';
import Error from '@material-ui/icons/ReportProblem';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';

import * as moment from "moment-timezone";
import Login from '../common/login';
import exportFromJSON from 'export-from-json'
import { Link } from 'react-router-dom';

const columns = [

    { field: "info", name: "المزيد", options: { width: 200, filter: false, sort: false, } },
    { field: "amount", name: "دين التاجر", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم التاجر", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: false, sort: false, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    print: false,
    sort: true,
    rowsPerPage: 50,

    filter: false,

    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            ordes2: [],
            dept: 0,


        }

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `dashbord/Suppliers/all`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.suppliers
                let dept = 0
                for (let i = 0; i < items.length; i++) {

                    let obj = {

                        id: items[i].id,
                        amount: items[i].amount,
                        name: items[i].name,

                        info: <Link to={`Supplier?id=${items[i].id}`}><Info /></Link>,
                        // print: <a href={`https://pages.enfirad.com/Print2/${items[i].id}`}><ReplayIcon style={{ color: 'green' }} /></a>
                    };

                    dept = dept + items[i].amount

                    arr.push(obj);
                }


                this.setState({
                    orders: arr, spin: false, dept

                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };


    Addsup(name, phone) {

        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.post(host + `dashbord/Suppliers/new/`, {
            name, phone

        })
            .then(response => {
                window.location.reload();

            })
            .catch(error => {

            });

    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >




                                <div id='navDashContiner'>
                                    <Component initialState={{ isShown: false, name: "", phone: "" }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة تاجر</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم التاجر </label>
                                                                <input autoComplete='off' type='text' placeholder='اسم التاجر' id='InputTExtDash111'
                                                                    onChange={(e) => setState({ name: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم التاجر </label>
                                                                <input autoComplete='off' type='text' placeholder='رقم الهاتف ' id='InputTExtDash111'
                                                                    onChange={(e) => setState({ phone: e.target.value })} />
                                                            </div>

                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                this.Addsup(state.name, state.phone)
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة تاجر</div>
                                            </React.Fragment>
                                        )}
                                    </Component>                                </div>
                                <div>
                                    <br></br>
                                    <div>
                                        <Alert key={"primary"} variant={"primary"}>
                                            <center>المجموع الكل للديون : {this.state.dept}</center>
                                        </Alert>

                                    </div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.orders} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;