import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { SideSheet, Position } from 'evergreen-ui';
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../common/login';
import { Button, Row, Col, Container, Alert } from 'react-bootstrap';

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `dashbord/category`, { headers: header })
            .then(res => {
                this.setState({
                    Category: res, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <Component initialState={{ isShown: false, nameUser: '', phoneUser: '', passwordUser: '', }}>
                                        {({ state, setState }) => (
                                            <React.Fragment>
                                                <SideSheet
                                                    isShown={state.isShown}
                                                    position={Position.LEFT}
                                                    onCloseComplete={() => setState({ isShown: false })}
                                                >
                                                    <div margin={40}>
                                                        <div id='titleAddUser' style={{ backgroundColor: '#000' }}>اضافة موظف</div>
                                                        <div id='inputAdd2Continer' >
                                                            <div id={'ContinerInPut'} >
                                                                <label>اسم الموظف</label>
                                                                <input autoComplete='off' type='text' placeholder='اسم الموظف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ nameUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>رقم الهاتف</label>
                                                                <input autoComplete='off' type='number' placeholder='رقم الهاتف' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ phoneUser: e.target.value })} />
                                                            </div>
                                                            <div id={'ContinerInPut'} >
                                                                <label>كلمه المرور</label>
                                                                <input autoComplete='off' type='text' placeholder='كلمه المرور' id='InputTExtDash1'
                                                                    onChange={(e) => setState({ passwordUser: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div id='btnAddContiner'>
                                                            <div id='BtnAddUser' style={{ backgroundColor: '#000' }} onClick={() => {
                                                                axios.post(host + `users/auth/register`,
                                                                    qs.stringify({ name: state.nameUser, password: state.passwordUser, phone: state.phoneUser }), {
                                                                    headers: {
                                                                        "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                                    }
                                                                })
                                                                    .then(response => {
                                                                        toast('تمت الاضافة بنجاح', {
                                                                            position: "bottom-center",
                                                                            autoClose: 5000,
                                                                            hideProgressBar: false,
                                                                            closeOnClick: true,
                                                                            pauseOnHover: true,
                                                                            draggable: true
                                                                        });

                                                                    })
                                                                    .catch(error => {
                                                                        console.log(error.response.data.message)
                                                                        this.setState({ spinerBtn: false })
                                                                    })
                                                                setState({ isShown: false })
                                                            }}>اضافة</div>
                                                        </div>

                                                    </div>
                                                </SideSheet>
                                                <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة موظف</div>
                                            </React.Fragment>
                                        )}
                                    </Component>

                                </div>
                                <br></br>
                                <Container>
                                    <Row>

                                        <Col>
                                            <Alert key={'primary'} variant={'primary'}>
                                                <h4 style={{ textAlign: 'center' }}>تقارير الطلبات</h4>
                                            </Alert>
                                        </Col>
                                    </Row>


                                    <Row>
                                        <Col>
                                            <Button href="/wattingselles" variant="primary" size="md">
                                                الانتظار</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/check" variant="primary" size="md">
                                                الغير مؤكد</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/miss" variant="primary" size="md">
                                                الايرد</Button>
                                        </Col>

                                        <Col>
                                            <Button href="/selles" variant="primary" size="md">
                                                المبيعات</Button>
                                        </Col>

                                    </Row>
                                </Container>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;