// const host = "https://inventory-api.lamha-iq.com/";
// const host = "https://api.lamha-iq.com/";


//  const host = "https://test-api.enfirad.com/";
const host = "https://api.enfirad.com/";
//  const host = "https://test-api.enfirad.com/";
// const host = "http://localhost:5000/";
export default host;

