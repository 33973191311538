/* eslint-disable no-loop-func */
import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import qs from 'qs';
import Component from "@reactions/component";
import { Pane, Dialog, Spinner, SideSheet, Position, Icon } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ImageIcon from '@material-ui/icons/Image';
import Campaign from '@material-ui/icons/AddAlert';

import RttIcon from '@material-ui/icons/FormatQuote';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from '../common/login';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 100, filter: true, sort: false, } },
    // { field: "edit", name: "تعديل", options: { width: 100, filter: true, sort: false, } },
    { field: "photo", name: "صورة", options: { width: 100, filter: true, sort: false, } },
    // { field: "editCount", name: "اضافة عدد", options: { width: 100, filter: true, sort: false, } },
    { field: "count", name: "العدد", options: { filter: true, sort: true, } },
    { field: "add_ad", name: "اضافه اعلان", options: { filter: true, sort: false, } },
    // { field: "price", name: "سعر البيع", options: { filter: true, sort: false, } },
    { field: "dwprice", name: "سعر الشراء", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المادة", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { filter: true, sort: true, } },

];
const ads_columns = [
    { field: "edit", name: "تعديل", options: { width: 100, filter: true, sort: false, } },

    { field: "photo", name: "صورة", options: { width: 100, filter: true, sort: false, } },
    { field: "body", name: "الوصف", options: { filter: true, sort: false, } },
    { field: "price", name: "سعر البيع", options: { filter: true, sort: false, } },
    { field: "count", name: "عدد القطع ", options: { filter: true, sort: false, } },

    { field: "name", name: "اسم المادة", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { filter: true, sort: true, } },

];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: false,
    rowsPerPage: 1001,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            CountItem: '',
            nameItem: '',
            priceItem: '',
            dw_price: '',
            barcode: 0,
            image: [],
            ads: [],
            ad_file: [],
            ad_file1: [],
            ad_file2: [],
            ad_file3: [],
            pages: []
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })
    editAd(id) {
        console.log(id)
        const name = document.getElementById('editName').value
        const body = document.getElementById('editBody').value
        const price = document.getElementById('editPrice').value
        const page = document.getElementById('selectPage').value
        const count = document.getElementById('editCount').value




        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", name);

        formData.append("price", price);
        formData.append("body", body);
        formData.append("page_id", page);
        formData.append("count", count);

        formData.append("file", this.state.ad_file);

        axios({ url: host + `dashbord/shop/items/edit/${id}`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount()

                toast('تم تعديل الاعلان', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data) })

    }
    add_Ad(id) {
        const name = document.getElementById('adName').value
        const body = document.getElementById('adBody').value
        const price = document.getElementById('adPrice').value
        const page = document.getElementById('adselectPage').value
        const count = document.getElementById('adCount').value
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", name);
        formData.append("item_id", id);
        formData.append("price", price);
        formData.append("count", count);
        formData.append("body", body);
        formData.append("page_id", page);
        formData.append("file", this.state.ad_file);
        formData.append("file", this.state.ad_file1);
        formData.append("file", this.state.ad_file2);
        formData.append("file", this.state.ad_file3);

        axios({ url: host + `dashbord/shop/items/add`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount()

                toast('تم اضافه الاعلان', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data) })

    }
    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let pages = []
        axios.get(host + `users/pages/name/all`, { headers: header })
            .then(res => {
                pages = res.data.data.pages

            })
            .catch(error => {
                console.log(error.response)
            });
        axios.get(host + `dashbord/component/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                for (let i = 0; i < res.data.data.itmes.length; i++) {
                    let obj = {
                        id: res.data.data.itmes[i].id,
                        name: res.data.data.itmes[i].name,
                        dwprice: res.data.data.itmes[i].dw_price,
                        price: res.data.data.itmes[i].price,
                        count: res.data.data.itmes[i].count,
                        barcode: res.data.data.itmes[i].barcode,
                        add_ad: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <SideSheet
                                        isShown={state.isShown}
                                        title='اضافة اعلان'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="اضافة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            // console.log(state.img);
                                            this.add_Ad(res.data.data.itmes[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div style={{ direction: 'rtl' }}>
                                            <div style={{ padding: 25 }}>

                                                <Form.Group dir='rtl' className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>اسم المنتج</Form.Label>
                                                    <Form.Control type="text" id="adName" placeholder="ادخل الاسم" />

                                                </Form.Group>
                                                <Form.Group dir='rtl' className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>السعر</Form.Label>
                                                    <Form.Control type="number" id="adPrice" placeholder="ادخل السعر" />

                                                </Form.Group>
                                                <Form.Group dir='rtl' className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>العدد </Form.Label>
                                                    <Form.Control type="number" id="adCount" placeholder="ادخل العدد" />

                                                </Form.Group>
                                                <Form.Group dir='rtl' className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>الوصف</Form.Label>
                                                    <Form.Control id="adBody" as="textarea" rows={3} />

                                                </Form.Group>
                                                <select className="selectPages" id="adselectPage" >
                                                    <option value={'All'}>اختر صفحة</option>
                                                    {pages.map((item, i) =>
                                                        <option value={item.id}>{item.name}</option>

                                                    )}

                                                </select >
                                                <hr></hr>
                                                <Form.Group controlId="formFile" className="mb-3" dir='rtl'>
                                                    <Form.Label>الصورة 1</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => {

                                                        this.setState({ ad_file: e.target.files[0] })
                                                    }} />
                                                </Form.Group>
                                                <Form.Group controlId="formFile" className="mb-3" dir='rtl'>
                                                    <Form.Label>الصورة 2</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => {

                                                        this.setState({ ad_file1: e.target.files[0] })
                                                    }} />
                                                </Form.Group>
                                                <Form.Group controlId="formFile" className="mb-3" dir='rtl'>
                                                    <Form.Label>الصورة 3</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => {

                                                        this.setState({ ad_file2: e.target.files[0] })
                                                    }} />
                                                </Form.Group>
                                                <Form.Group controlId="formFile" className="mb-3" dir='rtl'>
                                                    <Form.Label>الصورة 4</Form.Label>
                                                    <Form.Control type="file" onChange={(e) => {

                                                        this.setState({ ad_file3: e.target.files[0] })
                                                    }} />
                                                </Form.Group>
                                                <center>    <Button onClick={() => {


                                                    this.add_Ad(res.data.data.itmes[i].id)

                                                    setState({ isShown: false })
                                                }}> اضافة</Button></center>

                                            </div>
                                        </div>
                                    </SideSheet >
                                    <Campaign style={{ cursor: 'pointer', color: '#516fdc' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )
                            }
                        </Component>,
                        editCount: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='اضافة عدد'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="اضافة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            // console.log(state.img);
                                            this.EditCount(res.data.data.itmes[i].id)
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div style={{ direction: 'rtl' }}>
                                            <div id={'ContinerInPut'} >
                                                <label>العدد</label>
                                                <input autoComplete='off' type='number' placeholder='العدد الكلي ' id='coutsAll'
                                                />
                                            </div>
                                            {/* <div id={'ContinerInPut'} >
                                                <label>السعر الكلي</label>
                                                <input autoComplete='off' type='number' placeholder='' id='priceAll'
                                                />
                                            </div> */}
                                            {/* <div id={'ContinerInPut'} >
                                                <label>المورد</label>
                                                <input autoComplete='off' type='text' placeholder='اسم المورد ' id='nameAll'
                                                />
                                            </div> */}
                                        </div>
                                    </Dialog>
                                    <PlusOneIcon style={{ cursor: 'pointer', color: '#516fdc' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        photo: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasHeader={false}
                                        hasFooter={false}
                                    >
                                        <div>
                                            <img src={host + 'files/' + res.data.data.itmes[i].photo} alt='img' id='imageItemDash' />

                                        </div>
                                    </Dialog>
                                    <ImageIcon style={{ cursor: 'pointer', color: '#08aba3' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        edit: <EditIcon
                            onClick={() => {
                                window.open(`/Edit/${res.data.data.itmes[i].id}`)
                                localStorage.setItem('nameIT', res.data.data.itmes[i].name)
                                localStorage.setItem('SpriceIT', res.data.data.itmes[i].dw_price)
                                localStorage.setItem('PpriceIT', res.data.data.itmes[i].price)
                                localStorage.setItem('BarcodeIT', res.data.data.itmes[i].barcode)
                                localStorage.setItem('category_id', this.props.match.params.id)
                            }}
                            style={{ cursor: 'pointer', color: '#4752d1' }} />,
                        delete: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title="حذف قسم "
                                        intent="danger"
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="حذف"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            setState({ isShown: false })
                                            this.DeleteCate(res.data.data.itmes[i].id)
                                        }}
                                    >
                                        <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                                    </Dialog>
                                    <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error) })


        axios.get(host + `dashbord/shop/items`, { headers: header })
            .then(res => {

                let ads = [];

                for (let i = 0; i < res.data.items.length; i++) {
                    let obj = {
                        id: res.data.items[i].id,
                        name: res.data.items[i].name,
                        dwprice: res.data.items[i].dw_price,
                        price: res.data.items[i].price,
                        count: res.data.items[i].count,

                        body: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasHeader={false}
                                        hasFooter={false}
                                    >
                                        <div>
                                            <p>{res.data.items[i].body}</p>

                                        </div>
                                    </Dialog>
                                    <RttIcon style={{ cursor: 'pointer', color: '#08aba3' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                        photo: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasHeader={false}
                                        hasFooter={false}
                                    >
                                        <div>
                                            <img src={host + 'files/' + res.data.items[i].main_photo} alt='img' id='imageItemDash' />

                                        </div>
                                    </Dialog>
                                    <ImageIcon style={{ cursor: 'pointer', color: '#08aba3' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,

                        // delete: <Component initialState={{ isShown: false }}>
                        //     {({ state, setState }) => (
                        //         <Pane>
                        //             <Dialog
                        //                 isShown={state.isShown}
                        //                 title="حذف قسم "
                        //                 intent="danger"
                        //                 onCloseComplete={() => setState({ isShown: false })}
                        //                 confirmLabel="حذف"
                        //                 cancelLabel="الغاء"
                        //                 onConfirm={() => {
                        //                     setState({ isShown: false })
                        //                     this.DeleteCate(res.data.data.itmes[i].id)
                        //                 }}
                        //             >
                        //                 <span id='msgDelete'> هل انت متأكد من عملية الحذف</span>
                        //             </Dialog>
                        //             <DeleteForeverIcon style={{ cursor: 'pointer', color: '#ec7373' }}
                        //                 onClick={() => { setState({ isShown: true }) }} />
                        //         </Pane>
                        //     )}
                        // </Component>,
                        edit: <Component initialState={{ isShown: false }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        onCloseComplete={() => setState({ isShown: false })}
                                        hasHeader={false}
                                        hasFooter={true}
                                        onConfirm={() => {
                                            this.editAd(res.data.items[i].id)
                                            setState({ isShown: false })
                                        }}

                                    >
                                        <div>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>اسم المنتج</Form.Label>
                                                <Form.Control type="text" id="editName" placeholder="ادخل الاسم" />

                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>السعر</Form.Label>
                                                <Form.Control type="number" id="editPrice" placeholder="ادخل السعر" />

                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>العدد</Form.Label>
                                                <Form.Control type="number" id="editCount" placeholder="ادخل العدد" />

                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>الوصف</Form.Label>
                                                <Form.Control id="editBody" as="textarea" rows={3} />

                                            </Form.Group>
                                            <select className="selectPages" id="selectPage" >
                                                <option value={'All'}>اختر صفحة</option>
                                                {pages.map((item, i) =>
                                                    <option value={item.id}>{item.name}</option>

                                                )}

                                            </select >
                                            <Form.Group controlId="formFile" className="mb-3">
                                                <Form.Label>الصورة</Form.Label>
                                                <Form.Control type="file" onChange={(e) => {
                                                    console.log(e.target.files[0]);
                                                    this.setState({ ad_file: e.target.files[0] })
                                                }} />
                                            </Form.Group>

                                        </div>
                                    </Dialog>
                                    <EditIcon style={{ cursor: 'pointer', color: '#08aba3' }}
                                        onClick={() => { setState({ isShown: true }) }} />
                                </Pane>
                            )}
                        </Component>,
                    };
                    ads.push(obj);
                }
                this.setState({
                    ads, spin: true
                });
            })
            .catch(error => { console.log(error) })
    }
    DeleteCate(id) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.delete(host + `dashbord/component/${id}?`, { headers: header })
            .then(response => {

                toast('تم الحذف بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                this.componentDidMount();
            })
            .catch(error => { console.log(error) })
    }
    EditCount(id) {
        // const price = document.getElementById('priceAll').value
        const cout = document.getElementById('coutsAll').value
        // const name = document.getElementById('nameAll').value


        //    const s_price = Number(price) / Number(cout)


        axios.put(host + `dashbord/components/count/${id}`,
            qs.stringify({ count: cout, price: 0, name: "0" }), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
            }
        })
            .then(response => {
                this.componentDidMount();
                toast('تم اضافة العدد بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => {
                console.log(error.response.data.message)
                this.setState({ spinerBtn: false })
            })
    }
    AddItem(nameItem, priceItem, dw_price, barcode) {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        let formData = new FormData();
        formData.append("name", nameItem);
        formData.append("count", 0);
        formData.append("price", priceItem);
        formData.append("dw_price", dw_price);
        formData.append("barcode", barcode);
        formData.append("hasCount", true);
        formData.append("category_id", this.props.match.params.id);
        formData.append("file", this.state.image[0]);
        axios({ url: host + `dashbord/component/add/`, method: "POST", data: formData, headers: header })
            .then(response => {
                this.setState({ spinerBtn: false })
                this.componentDidMount()

                toast('تم اضافة المادة بنجاح', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
            })
            .catch(error => { console.log(error.response.data) })
    }
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Link to='/Cate2'>
                                            <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                style={{ cursor: 'pointer' }} />
                                        </Link>
                                        <Component initialState={{ isShown: false, nameItem: "", dw_price: 0, priceItem: 0, barcode: "", priceItem: 0, }}>
                                            {({ state, setState }) => (
                                                <React.Fragment>
                                                    <SideSheet
                                                        isShown={state.isShown}
                                                        position={Position.LEFT}
                                                        onCloseComplete={() => setState({ isShown: false })}
                                                    >
                                                        <div margin={40} style={{ direction: 'rtl' }}>
                                                            <div id='titleAddAppBar'>اضافة مادة</div>
                                                            <div id='inputAddContiner'>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>اسم المادة </label>
                                                                    <input autoComplete='off' type='text' placeholder='اسم المادة ' id='InputTExtDash1'
                                                                        onChange={(e) => setState({ nameItem: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>سعر الشراء </label>
                                                                    <input autoComplete='off' type='number' placeholder='سعر الشراء ' id='InputTExtDash1'
                                                                        onChange={(e) => setState({ dw_price: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>سعر البيع </label>
                                                                    <input autoComplete='off' type='number' placeholder='سعر البيع ' id='InputTExtDash1'
                                                                        onChange={(e) => setState({ priceItem: e.target.value })} />
                                                                </div>
                                                                <div id={'ContinerInPut'} >
                                                                    <label>الباركود</label>
                                                                    <input autoComplete='off' type='number' placeholder='الباركود' id='InputTExtDash1'
                                                                        onChange={(e) => setState({ barcode: e.target.value })} />
                                                                </div>

                                                                <div id={'ContinerInPut'}>
                                                                    <label>اختر صورة</label>
                                                                    <input type='file'
                                                                        id='InputTExtDash111Image'
                                                                        onChange={file => {
                                                                            console.log(file.target.files);
                                                                            this.setState({ image: file.target.files })
                                                                        }}
                                                                        placeholder="Select the file "
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div id='btnAddItemContiner'>
                                                                {!this.state.spinerBtn ? (
                                                                    <div id='btnAddItem' onClick={() => {
                                                                        this.setState({ spinerBtn: true })
                                                                        this.AddItem(state.nameItem, state.priceItem, state.dw_price, state.barcode)
                                                                        setState({ isShown: false })
                                                                    }}>اضافه</div>

                                                                ) : (
                                                                    <div id='btnAddItem'><Spinner size={24} /></div>

                                                                )}


                                                            </div>

                                                        </div>
                                                    </SideSheet>
                                                    <div id='BtnAddUser' style={{ backgroundColor: '#fff', color: '#000', height: 30, margin: 20 }} onClick={() => setState({ isShown: true })}>اضافة مادة</div>
                                                </React.Fragment>
                                            )}
                                        </Component>

                                    </div>

                                    <Tabs
                                        defaultActiveKey="home"
                                        id="uncontrolled-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="home" title="المواد">
                                            <div className='DataTableContiner'>
                                                <MuiThemeProvider
                                                    theme={this.getMuiTheme()}>
                                                    <MaterialDatatable data={this.state.Category} columns={columns} options={options} />
                                                </MuiThemeProvider>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="profile" title="الاعلانات">
                                            <MuiThemeProvider
                                                theme={this.getMuiTheme()}>
                                                <MaterialDatatable data={this.state.ads} columns={ads_columns} options={options} />
                                            </MuiThemeProvider>
                                        </Tab>

                                    </Tabs>

                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;