import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import qs from 'qs';
import CategoryIcon from '@material-ui/icons/ArrowBack';
import * as moment from "moment-timezone";
import { Link } from 'react-router-dom';
import Login from '../common/login';
const columns = [
    { field: "re_count", name: "عدد الاستبدال ", options: { filter: true, sort: true, } },
    { field: "count", name: "العدد ", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المنتج", options: { filter: true, sort: true, } },

];
const columns1 = [
    { field: "price", name: "الكلي للمنتج ", options: { filter: true, sort: true, } },
    { field: "count", name: "العدد الكلي", options: { filter: true, sort: true, } },
    { field: "name", name: "اسم المنتج", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: true,
    print: false,
    filter: false,
    rowsPerPage: 30000,
    download: false,
    onSearchChange: (e) => {
        console.log(e);
    },
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();

class Selles_Reports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            from: new Date(),
            to: new Date(),
            spin: false,
            serch: false,
            pages: [],
            page: 'كل الصفحات',
            total: '',
            total_items: 0,
            total_orders: 0,
            items_cost: 0,
            orders_cost: 0,
            print_id: "",
            cash: 0,
            total_price: 0,
            cash_orders: 0,
            mat: 0,
            order_count: 0,
            name: "",
            createdAt: "",
            baghdad_data: [],
            other_data: [],
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);

        let Getid = urlParams.get('id')

        var header = { "Content-Type": "application/json", token: cookies.get("token") };

        axios.get(host + `users/rejected/items/prices/${Getid}`, { headers: header })
            .then(res => {
                let arr = [];
                let items = res.data.orders
         
                let t_price = 0


                for (let i = 0; i < items.length; i++) {
                    let obj = {
                        id: i,
                        name: <p>{items[i].name}</p>,
                        count: <p>{items[i].count}</p>,
                        price: <p>{items[i].price}</p>,
                    };
        
                    t_price = t_price + items[i].price;

                    arr.push(obj);

                }

          

                this.setState({
                    total_price: t_price,
                    orders: arr,
                    spin: false,
                    name: res.data.name,
                    createdAt: res.data.createdAt,
                    print_id: Getid,
                    total_orders:res.data.orders_count

                });

            })
            .catch(error => {
                console.log(error.response)
            });

    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >

                                <div id='navDashContiner'>
                                    <Link to={`/rejctedList`}>  <CategoryIcon style={{ color: '#fff', cursor: "pointer" }} /></Link>
                                    {/* <button
                                        onClick={() => {
                                            this.download()
                                        }}
                                    ></button> */}
                                </div>
                                <div id='ContinerReportPageName'>
                                    <Row id='RowReportDate'>
                                        <Col >
                                            <center>  <b><h4>رقم القائمة : &nbsp; {this.state.print_id}</h4></b></center>

                                            <center>  <b><h4> {moment(this.state.createdAt).tz('Asia/baghdad').format('YYYY/MM/DD LT')}</h4></b></center>
                                            <center>  <b><h4> الاسم : &nbsp; {this.state.name}</h4></b></center>

                                        </Col>

                                    </Row>
                                </div>
                                <div>


                                    <Row>
                                        <Col>
                                            <div className='DataTableContiner'>
                                                <MuiThemeProvider
                                                    theme={this.getMuiTheme()}>
                                                    <MaterialDatatable title='الكلي' data={this.state.orders} columns={columns1} options={options} />
                                                </MuiThemeProvider>
                                            </div>
                                        </Col>

                                    </Row>


                                    <div id='ContinerReportDate1' style={{ justifyContent: 'end' }}>

                                        <div style={{ marginRight: 20 }}>{this.state.total_price}</div>
                                        <div id='AddClientDeptBTN1' >مبلغ الكلي</div>
                                    </div>

                                    <div id='ContinerReportDate1' style={{ justifyContent: 'end' }}>

                                        <div style={{ marginRight: 20 }}>{this.state.total_orders}</div>
                                        <div id='AddClientDeptBTN12'> عدد الطلبات</div>
                                    </div>

                                </div>

                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Selles_Reports;