import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Pane, Dialog, Spinner, Icon } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import qs from 'qs';
import Component from "@reactions/component";
import { ToastContainer, toast } from 'react-toastify';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Login from './login';

const cookies = new Cookies();
const columns = [
    { field: "shop", name: "شراء", options: { width: 60, filter: true, sort: false, } },
    { field: "count", name: "العدد", options: { filter: true, sort: false, } },
    { field: "barcode", name: "الباركود", options: { filter: true, sort: false, } },
    { field: "price", name: "السعر ", options: { filter: true, sort: false, } },
    { field: "name", name: "اسم المادة", options: { filter: true, sort: true, } },
    { field: "photo", name: "صورة", options: { width: 150, height: 150, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            spin: false,
        }
    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },
            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser") };
        axios.get(host + `dashbord/component/${this.props.match.params.id}`, { headers: header })
            .then(res => {
                let arr = [];
                console.log(res.data.data.itmes);
                for (let i = 0; i < res.data.data.itmes.length; i++) {
                    let obj = {
                        photo: <div> <img src={host + 'files/' + res.data.data.itmes[i].photo} alt='img' style={{ width: 100, height: 100 }} /> </div>,
                        name: res.data.data.itmes[i].name,
                        price: res.data.data.itmes[i].price,
                        count: res.data.data.itmes[i].count,
                        barcode: res.data.data.itmes[i].barcode,
                        shop: <Component initialState={{ isShown: false, total: 1 }}>
                            {({ state, setState }) => (
                                <Pane>
                                    <Dialog
                                        isShown={state.isShown}
                                        title='اضافة الى السله'
                                        onCloseComplete={() => setState({ isShown: false })}
                                        confirmLabel="اضافة"
                                        cancelLabel="الغاء"
                                        onConfirm={() => {
                                            // console.log(state.img);
                                            axios.post(host + `users/ccart/add/${res.data.data.itmes[i].id}`,
                                                qs.stringify({ count: state.total }), {
                                                headers: {
                                                    "Content-Type": "application/x-www-form-urlencoded", Authorization: cookies.get("tokenUser")
                                                }
                                            })
                                                .then(response => {
                                                    toast('تمت الاضافة الى السلة', {
                                                        position: "bottom-center",
                                                        autoClose: 5000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true
                                                    });
                                                    this.componentDidMount();
                                                    setState({ spin: false })
                                                })
                                                .catch(error => {
                                                    console.log(error.response.data.message)
                                                    setState({ spin: false })
                                                })
                                            setState({ isShown: false })
                                        }}
                                    >
                                        <div style={{ direction: 'rtl' }}>
                                            <div id='inputAddContiner'>
                                                <div id={'ContinerInPut'} >
                                                    <label>العدد</label>
                                                    <input autoComplete='off' type='number' placeholder='العدد' id='InputTExtDash1'
                                                        value={state.total} onChange={(e) => setState({ total: e.target.value })} />
                                                </div>
                                            </div>

                                        </div>
                                    </Dialog>
                                    <div id='MenuBtnCateContTa'>
                                        <ShoppingBasketIcon style={{ cursor: 'pointer', color: '#000' }}
                                            onClick={() => { setState({ isShown: true }) }} />
                                    </div>
                                </Pane>
                            )}
                        </Component>,
                    };
                    arr.push(obj);
                }
                this.setState({
                    items: arr, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div >
                                    <div id='navDashContiner'>
                                        <Link to='/Category2'>
                                            <Icon icon="circle-arrow-left" size={30} color="#fff"
                                                style={{ cursor: 'pointer' }} />
                                        </Link>
                                        <h6>{localStorage.getItem('cateName')}</h6>
                                    </div>
                                    <div className='DataTableContiner'>
                                        <MuiThemeProvider
                                            theme={this.getMuiTheme()}>
                                            <MaterialDatatable data={this.state.items} columns={columns} options={options} />
                                        </MuiThemeProvider>
                                    </div>
                                    <ToastContainer
                                        position="bottom-left"
                                        autoClose={10000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        rtl={false}
                                        pauseOnVisibilityChange
                                        draggable
                                        pauseOnHover
                                    />
                                </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: '100vh ', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Spinner />
                                </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;