import React from 'react';
import Context from '../../assets/js/Context';
import Component from "@reactions/component";
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { SideSheet, Position } from 'evergreen-ui';
import qs from 'qs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../common/login';
import { Button, Row, Col, Container, Form, Card, ListGroup } from 'react-bootstrap';
import { Link } from '@material-ui/core';

const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            Category: [],
            spin: false,
            Schedule: ""
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token") };
        axios.get(host + `users/schedule/type`, { headers: header })
            .then(res => {
                console.log(res.data[0].value);
                if (res.data[0].value == 0) {
                    document.getElementById('schedule_0').checked = true

                } else if (res.data[0].value == 1) {
                    document.getElementById('schedule_1').checked = true
                } else {
                    document.getElementById('schedule_2').checked = true
                }
                this.setState({
                    Schedule: res.data[0].value, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }

    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <br></br>
                                <br></br>

                                <br></br>
                                <br></br>
                                <Container>
                                    <br></br>
                                    <Row >
                                        <Col >
                                            <Card dir='rtl' style={{ width: '18rem', textAlign: 'center' }}>
                                                <Card.Body>
                                                    <Card.Title>نظام توزيع الطلبات </Card.Title>

                                                </Card.Body>
                                                <ListGroup className="list-group-flush" >
                                                    <Form.Check
                                                        style={{ margin: '10px', textAlign: 'center' }}
                                                        inline
                                                        label="Automated"
                                                        onChange={() => {
                                                            this.setState({ Schedule: 0 })
                                                        }}
                                                        name="group1"
                                                        type={"radio"}
                                                        id={`schedule_0`}
                                                    />

                                                    <Form.Check
                                                        style={{ margin: '10px', textAlign: 'center' }}
                                                        inline
                                                        label="حسب الجدول"
                                                        name="group1"
                                                        onChange={() => {
                                                            this.setState({ Schedule: 1 })
                                                        }}
                                                        type={"radio"}
                                                        id={`schedule_1`}
                                                    />
                                                    <Form.Check
                                                        style={{ margin: '10px', textAlign: 'center' }}
                                                        inline
                                                        label="بالتساوي"
                                                        name="group1"
                                                        type={"radio"}
                                                        onChange={() => {
                                                            this.setState({ Schedule: 2 })
                                                        }}
                                                        id={`schedule_2`}
                                                    />

                                                </ListGroup>
                                                <Card.Body>
                                                    <Button variant="primary" onClick={() => {
                                                        axios.post(host + `users/edit/Schedule/`,
                                                            qs.stringify({ value:this.state.Schedule }), {
                                                            headers: {
                                                                "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("token")
                                                            }
                                                        })
                                                            .then(response => {
                                                                toast('تمت التعديل التوقيت بنجاح', {
                                                                    position: "bottom-center",
                                                                    autoClose: 5000,
                                                                    hideProgressBar: false,
                                                                    closeOnClick: true,
                                                                    pauseOnHover: true,
                                                                    draggable: true
                                                                });
                                                                console.log(this.state.Schedule);
                                                            })
                                                            .catch(error => {
                                                                console.log(error.response.data.message)
                                                                this.setState({ spinerBtn: false })
                                                            })
                                                    }} > حفظ</Button>

                                                </Card.Body>
                                            </Card>
                                            <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                                            {/* <Form.Check // prettier-ignore
                                                type="switch"
                                                id="custom-switch"
                                                label="تعيل نظام الجدول"
                                            /> */}

                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col>
                                            <Button size="lg" href='/Schedule' variant="primary">جدول الموظفين</Button>
                                        </Col>
                                        <Col>
                                            <Button size="lg"  href='/Employee'  variant="primary">تقارير</Button>
                                        </Col>
                                        <Col>
                                            <Button size="lg"  href='/callsUsers' variant="primary">معالجة الطلبات</Button>
                                        </Col>
                                    </Row>
                                </Container>
                            </div >
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;