import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Login from '../common/login';
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
const cookies = new Cookies();

class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nameCate: '',
            image: [],
            Category: [],
            spin: false,
        }
    }

    componentDidMount() {
        var header = { "Content-Type": "application/x-www-form-urlencoded", token: cookies.get("tokenUser") };
        axios.get(host + `dashbord/category`, { headers: header })
            .then(res => {
                console.log(res.data.data.categories);
                var arr = []
                for (let i = 0; i < res.data.data.categories.length; i++) {
                    if (res.data.data.categories[i].is_count) {
                        arr.push(res.data.data.categories[i])
                    }
                }
                this.setState({
                    Category: arr, spin: true
                });
            })
            .catch(error => { console.log(error.response) })

    }
    render() {
        if (cookies.get("tokenUser")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        if (this.state.spin) {
                            return (
                                <div>
                                    <Row style={{ margin: 0 }}>
                                        {this.state.Category.map((cate, i) =>
                                            <Col key={i} id='ColCateGory' xs={12} md={6} lg={3} >
                                                <div id='imageCateContiner'>
                                                    <Link to={`/Item2/${cate.id}`} onClick={() => localStorage.setItem('cateName', cate.name)}>
                                                        <img id='imageCateGory' src={host + 'files/' + cate.photo} alt='cate' />
                                                        <h6 style={{ marginTop: 10, textAlign: 'center' }}>{cate.name}</h6>
                                                    </Link>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                    </div>
                            )
                        }
                        else {
                            return (
                                <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="sweet-loading">
                                    <ClipLoader
                                        css={override}
                                        size={100}
                                        color={"#fff"}
                                        loading={this.state.loading}
                                    />
                                </div>
                            </div>
                            )
                        }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;