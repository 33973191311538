import React from 'react';
import Context from '../../assets/js/Context';
import Cookies from "universal-cookie";
import host from '../../assets/js/Host';
import axios from 'axios';
import { Row, Col, Form, Card, ListGroup, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Component from "@reactions/component";
import { SideSheet, Position, Pane, Dialog } from 'evergreen-ui';
import { ToastContainer, toast } from 'react-toastify';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';

import InfoIcon from '@material-ui/icons/Info';
// import Lottie from 'lottie-react-web';
// import animation from '../../assets/json/clean.json';
import 'react-toastify/dist/ReactToastify.css';
import MaterialDatatable from "material-datatable";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import qs from 'qs';
import DesktopAccessDisabledIcon from '@material-ui/icons/DesktopAccessDisabled';
import * as moment from "moment-timezone";
import Login from '../common/login';
import DesktopMacIcon from '@material-ui/icons/DesktopWindows';
const columns = [
    // { field: "delete", name: "حذف", options: { width: 60, filter: true, sort: false, } },
    //   { field: "date", name: "تاريخ الاضافة ", options: { filter: true, sort: false, } },
    // { field: "add_date", name: " ", options: { filter: true, sort: false, } },

    { field: "info", name: "التفاصيل", options: { filter: true, sort: false, } },

    { field: "name", name: "الاسم", options: { filter: true, sort: true, } },
    { field: "id", name: "#", options: { width: 200, filter: true, sort: false, } },
];
const options = {
    selectableRows: false,
    responsive: 'scroll',
    rowCursorHand: false,
    sort: false,
    print: false,
    filter: false,
    download: false,
    textLabels: {
        body: {
            noMatch: " لم يتم العثور على سجلات مطابقة",
            toolTip: "فرز",
        },
        pagination: {
            next: "الصفحة التالية",
            previous: "الصفحة السابقة",
            rowsPerPage: "عدد الصفوف",
            displayRows: "من",
        },
        toolbar: {
            search: "بحث",
            downloadCsv: "تنزيل",
            print: "Print",
            viewColumns: " التحكم بالاعمدة",
            filterTable: "فلتر",
        },
        filter: {
            all: "الكل",
            title: "فلتر",
            reset: "إعادة تعيين",
        },
        viewColumns: {
            title: "عرض الأعمدة",
            titleAria: "إظهار / إخفاء أعمدة الجدول",
        },

    }
}
const cookies = new Cookies();
let categories = []
class Category extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            users: [],
            spin: false,
            serch: false,
            total: '',
            from: new Date(),
            to: new Date(),
            "done": 0,
            "new_order": 0,
            "miss": 0,
            "other": 0,
            "delverd": 0,
            "all": 0,
            "moj": 0,
            "company": 0,
            senders: [],
        }



    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MaterialDatatableBodyCell: {
                root: {
                    //   backgroundColor: "#FF0000",
                    textAlign: 'right',
                },

            },
            MuiTableCell: {
                root: {
                    textAlign: 'right',

                },
                head: {
                    // backgroundColor: "#FF0000",
                }
            },

        }
    })

    componentDidMount() {

        let from = this.state.from
        let to = this.state.to
        console.log(from, to);
        axios.get(host + `users/calls/reports/users?from=${from}&to=${to}`,)
            .then(res => {
                let arr = [];
                this.setState({
                    users: res.data.data,
                    "done": res.data.all_count.done,
                    "new_order": res.data.all_count.new_order,
                    "miss": res.data.all_count.miss,
                    "other": res.data.all_count.other,
                    "delverd": res.data.all_count.delverd,
                    "all": res.data.all_count.all,
                    "moj": res.data.all_count.moj,
                    "company": res.data.all_count.company,
                    senders: res.data.senders
                });
            })
            .catch(error => {
                console.log(error.response)
            });
    }
    handleChangeFrom = date => {
        console.log(date);

        this.setState({
            from: date
        });
    };
    handleChangeTo = date => {
        this.setState({
            to: date
        });
    };
    render() {
        if (cookies.get("token")) {
            return (
                <Context.Consumer>
                    {ctx => {
                        // if (this.state.spin) {
                        return (
                            <div >
                                <div id='navDashContiner'>
                                    <a href={`/EmployeeList`}><KeyboardBackspace style={{ color: '#fff', cursor: 'pointer', fontSize: 35 }} /></a>

                                </div>

                                <div id='ContinerReportDatePages'>
                                    <Row id='RowReportDate' style={{ flexDirection: "row-reverse" }}>
                                        <Col id='ColReportDate' sm={12} md={4}>

                                            <DatePicker id='date1'

                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.from}
                                                onChange={this.handleChangeFrom}
                                            />
                                            <span style={{ marginLeft: 10 }}> : من&nbsp;</span>
                                        </Col>
                                        <Col id='ColReportDate' sm={12} md={4}>

                                            <DatePicker id='date22'


                                                selected={this.state.to}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={this.handleChangeTo}
                                            />
                                            <span style={{ marginLeft: 10 }}> : الى &nbsp;</span>
                                        </Col>




                                        <Col id='ColReportDate' sm={12} md={4}>
                                            <div id='AddClientDeptBTN' onClick={() => {
                                                this.setState({ spin: true, serch: true })

                                                this.componentDidMount()
                                            }}>بحث</div>
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <br></br>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Card style={{ background: "#007bff" }}>
                                                    <Card.Header style={{ textAlign: "center" }}><b>التحويل</b></Card.Header>
                                                    <ListGroup variant="flush">
                                                        {this.state.senders.map((order, index) => (
                                                            <ListGroup.Item style={{ textAlign: 'end' }}> {order.counts} : {order.OrdersCalsUsers_user_name}</ListGroup.Item>


                                                        ))}

                                                    </ListGroup>
                                                </Card>

                                            </Col>
                                        </Row>
                                        <br></br>
                                        <hr></hr>
                                        <Row >


                                            <Col>
                                                <Card style={{ background: "#007bff" }}>
                                                    <Card.Header style={{ textAlign: "center" }}><b>الكل</b></Card.Header>
                                                    <ListGroup variant="flush">
                                                        <ListGroup.Item style={{ textAlign: 'end' }}>العدد الكلي : {this.state.all}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}>الطبات الغير معالجة  : {this.state.new_order}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}>لايرد  : {this.state.miss}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}>على شركة التوصيل  : {this.state.company}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}>اخرى  : {this.state.other}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}> مؤجل : {this.state.moj}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}> تم المعالجة : {this.state.done}</ListGroup.Item>
                                                        <ListGroup.Item style={{ textAlign: 'end' }}> تم  التسليم : {this.state.delverd}</ListGroup.Item>

                                                    </ListGroup>
                                                </Card>

                                            </Col>

                                        </Row>
                                        <br></br>
                                        <hr></hr>
                                        <Row >
                                            {this.state.users.map((order, index) => (
                                                <Col>
                                                    <Card>
                                                        <Card.Header style={{ textAlign: "center" }}><b>{order.name}</b></Card.Header>
                                                        <ListGroup variant="flush">
                                                            <ListGroup.Item style={{ textAlign: 'end' }}>العدد الكلي : {order.all}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}>الطبات الغير معالجة  : {order.new_order}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}>لايرد  : {order.miss}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}>على شركة التوصيل  : {order.company}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}>اخرى  : {order.other}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}> مؤجل : {order.moj}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}> تم المعالجة : {order.done}</ListGroup.Item>
                                                            <ListGroup.Item style={{ textAlign: 'end' }}> تم  التسليم : {order.delverd}</ListGroup.Item>

                                                        </ListGroup>
                                                    </Card>

                                                </Col>

                                            ))}
                                        </Row>
                                    </Container>
                                </div>
                                <ToastContainer
                                    position="bottom-left"
                                    autoClose={10000}
                                    hideProgressBar={false}
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnVisibilityChange
                                    draggable
                                    pauseOnHover
                                />
                            </div>
                        )
                        // }
                        // else {
                        //     return (
                        //         <div style={{ width: '100%', height: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //             <Lottie
                        //                 options={{
                        //                     animationData: animation
                        //                 }}
                        //             />
                        //         </div>
                        //     )
                        // }
                    }
                    }
                </Context.Consumer>
            )
        }
        else {
            return (
                <Login />
            )
        }
    }
}


export default Category;